// src/common/types.ts

// What? Defines TypeScript interfaces for the application's data types.
// Why? To ensure consistency and type safety across different parts of the application.
// How?
// - Defines interfaces for User, WorkspaceUser, UserWorkspacesType, UserWorkspacePermissions, WorkspaceType, FileType, SubscriptionDetails, ResourceUsageData, Resource, UsageTrendData, BillingInfo, BillingHistoryItem, Tier, RequestPackage, AddRequestsModalProps, IntegrationType, Integration, IntegrationFieldMeta, IntegrationConfigMeta, and IntegrationConfigMetadata.
// - Provides a clear structure for data that is used throughout the application.

export interface User {
    id: string;
    name: string;
    role: string;
    status: string;
    email: string;
  }

export interface WorkspaceUser {
  user_id: string
  email: string;
  role: string;
  status: string;
}
  
export interface UserWorkspacesType {
  id: string;
  name: string;
  role: string;
  status: string;
  subscription: object;
  permissions: UserWorkspacePermissions
}

export interface UserWorkspacePermissions {
  role: string;
  user_id: string;
  status: string;
  is_only_owner?: boolean;
}

export interface WorkspaceType {
  id: string;
  name: string;
  permissions: {
    role: string;
    status: string;
    is_only_owner?: boolean;
  };
  status: string;
  description: string;  
  assistant_instructions: string;  
  subscription: {
    name: string | null;
    expiry_date: string | null;
    capacity: number | null;
  } | null;
}

export interface FileType {
  id: string;
  name: string;
  path: string;
  node_type: string;
  last_modified?: Date | null; 
  isUploading?: boolean; 
}

export interface SubscriptionDetails {
  currentTier: string;
  monthlyPrice: number;
  yearlyPrice: number;
  benefits: string[];
}

export interface ResourceUsageData {
  resources: Resource[];
}

export interface Resource {
  name: string;
  used: number;
  total: number;
  percentage: number;
  message: string;
}

export interface UsageTrendData {
  labels: string[];
  datasets: Dataset[];
  options: any;
}

export interface Dataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  tension: number;
  fill: boolean;
}

export interface BillingInfo {
  paymentMethod: string;
  nextBillingDate: string;
  billingCycle: string;
}

export interface BillingHistoryItem {
  date: string;
  description: string;
  amount: number;
}

export interface Tier {
  name: string;
  price: string; // Price/month with monthly billing
  priceYearlyBilling: string | null | undefined; // Price/month with yearly billing
  features: string[];
  isCurrent: boolean;
  actionLabel: string;
  iconName: string | null | undefined; // NOTE: If a new icon is used, the name must also be added in UserUpgrade.tsx
}

export interface RequestPackage {
  amount: number;  // Number of requests in the package
  price: number;   // Price of the package
  discount: number;  // Discount percentage applied to the package (if any)
}

export interface AddRequestsModalProps {
  open: boolean;
  onClose: () => void;
  handleRequestPurchase: (amount: number) => void;
}

export interface IntegrationType {
  name: string;
  description: string;
  icon: React.ReactNode;
  type: string;
  configurable: boolean;
  allowMultipleConfigs: boolean;
  configureObjectType: string; // Name of the configuration interface
}

export interface Integration {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  type: string;
  configurable: boolean;
  allowMultipleConfigs: boolean;
  configureObjectType?: string;
  config?: any;
  connections?: string[];
  enabled?: boolean;
  configMeta?: IntegrationConfigMeta;
}

export interface IntegrationFieldMeta {
  name: string;
  label: string;
  type: 'text' | 'password' | 'number' | 'checkbox' | 'array';
  placeholder?: string;
  required?: boolean;
  min?: number;  // For number inputs (e.g., max_depth >= 1)
  max?: number;  // For number inputs (e.g., max_depth <= 10)
  fields?: IntegrationFieldMeta[];  // For nested fields (including arrays of objects)
  validationMessage?: string; // Custom error message to display if validation fails
}

export interface IntegrationConfigMeta {
  displayField?: string;
  fields: IntegrationFieldMeta[];
}

export const IntegrationConfigMetadata: { [key: string]: IntegrationConfigMeta } = {
  SharepointConfig: {
    displayField: 'tenant_id', // New field to specify the display field
    fields: [
      { name: 'tenant_id', label: 'Tenant ID', type: 'text', required: true, validationMessage: 'Tenant ID is required.' },
      { name: 'client_id', label: 'Client ID', type: 'text', required: true, validationMessage: 'Client ID is required.' },
      { name: 'secret_key', label: 'Secret Key', type: 'password', required: true, validationMessage: 'Secret Key is required.' },
      {
        name: 'site_url',
        label: 'Site URL',
        type: 'array',
        required: true,
        validationMessage: 'At least one site URL is required.',
        fields: [
          { name: 'prefix', label: 'Prefix', type: 'text', required: false },
          { name: 'site_url', label: 'Site URL', type: 'text', required: true, validationMessage: 'Site URL is required.' },
        ],
      },
    ],
  },
  URLCrawlerConfig: {
    displayField: 'urls', // Display the first URL in the list as the identifier
    fields: [
      { name: 'urls', label: 'URLs', type: 'array', placeholder: 'URL to crawl', required: true, validationMessage: 'At least one URL is required.' },
      { name: 'max_depth', label: 'Max Depth', type: 'number', min: 1, max: 10, required: true, validationMessage: 'Max Depth must be between 1 and 10.' },
      { name: 'max_domains', label: 'Max Domains', type: 'number', min: 1, required: false, validationMessage: 'Max Domains must be at least 1.' },
      { name: 'leafs', label: 'Include Leafs', type: 'checkbox', required: false },
    ],
  },
};