// src/views/WorkspaceSources.tsx

// What? A React component for managing integrations and sources in a workspace.
// Why? To allow the user to manage the integrations and sources for their workspace.
// How?
// - Fetches the integrations and sources using getIntegrations.
// - Displays the integrations and sources in a table.
// - Allows the user to add, configure, and delete integrations and sources.

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetIntegrations,
  useUpdateIntegration,
  useCreateIntegration,
  useDeleteIntegration,
} from '../services/api';
import {
  Integration, IntegrationConfigMetadata
} from '../common/types';
import { Table, CircularProgress, Button, Typography } from '@mui/joy';
import IntegrationModal from '../components/Modals/IntegrationModal';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import '../styles/WorkspaceSources.css';
import availableIntegrations from '../config/integrationsConfig';

const WorkspaceSources: React.FC = () => {
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isNewConfig, setIsNewConfig] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [configToDelete, setConfigToDelete] = useState<{ integrationName: string, configId: string, displayName?: string } | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const getIntegrations = useGetIntegrations();
  const updateIntegration = useUpdateIntegration();
  const createIntegration = useCreateIntegration();
  const deleteIntegration = useDeleteIntegration();

  const isFetchedRef = useRef<boolean>(false);

  // What? A function to fetch the integrations and sources from the API.
  // Why? To populate the integrations and sources in the component.
  // How?
  // - Sets loading to true to show a spinner.
  // - Tries to fetch the integrations and sources using getIntegrations.
  // - Updates the integrations and sources in the state.
  // - Sets loading to false after fetching the data.
  const fetchIntegrations = useCallback(async () => {
    if (!workspace_id || hasFailed) {
      console.error('Workspace ID is missing or previous request has failed.', error);
      return;
    }
  
    setLoading(true);
    setError(null); // Reset error before fetching
    try {
      const configuredIntegrations = await getIntegrations(workspace_id);
  
      const integrationsWithConfig: Integration[] = availableIntegrations.map((integrationType) => {
        const configMeta = IntegrationConfigMetadata[integrationType.configureObjectType] || null;

        const configurations = configuredIntegrations.filter(
          (ci) => ci.type === integrationType.type
        );

        const configurable = configMeta !== null && configurations.length > 0;

        const integration: Integration = {
          ...integrationType,
          id: integrationType.type,
          config: configurations.length > 0 ? configurations : null,
          configurable,
          connections: [],
          enabled: configurations.length > 0,
          configMeta,
        };
        return integration;
      });
  
      setIntegrations(integrationsWithConfig);
      isFetchedRef.current = true;
    } catch (error: any) {
      console.error('Failed to fetch integrations. Please try again later.', error);
      setHasFailed(true);
    } finally {
      setLoading(false);
    }
  }, [workspace_id, getIntegrations, hasFailed]);

  useEffect(() => {
    if (isFetchedRef.current || hasFailed) {
      return;
    }
    fetchIntegrations();
  }, [fetchIntegrations, hasFailed]);

  // What? A function to handle the configuration of an integration.
  // Why? To allow the user to configure an integration.
  // How?
  // - Sets the selected integration in the state.
  // - Opens the integration modal.
  const handleConfigure = useCallback((integration: Integration, configItem?: any) => {
    setSelectedIntegration({
      ...integration,
      config: configItem ? [configItem] : [{}],
    });
    setIsNewConfig(!configItem);
    setModalOpen(true);
  }, []);

  // What? A function to handle the toggling of an integration.
  // Why? To allow the user to enable or disable an integration.
  // How?
  // - Sets the selected integration in the state.
  // - Opens the integration modal.
  const handleToggle = useCallback(async (integration: Integration) => {
    if (!workspace_id) {
      setError('Workspace ID is missing.');
      return;
    }

    try {
      let result: Integration;
      if (integration.enabled) {
        result = { ...integration, enabled: false };
      } else {
        result = await createIntegration(integration.type, workspace_id, { ...integration, enabled: true });
      }
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((intg) => (intg.type === result.type ? result : intg))
      );
    } catch (error: any) {
      setError('Failed to update integration. Please try again later.');
    }
  }, [workspace_id, createIntegration]);

  // What? A function to handle the closing of the integration modal.
  // Why? To close the integration modal and reset the selected integration in the state.
  // How?
  // - Closes the integration modal.
  // - Resets the selected integration in the state.
  // - Resets the isNewConfig in the state.
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setSelectedIntegration(null);
    setIsNewConfig(false);
  }, []);

  // What? A function to handle the saving of an integration configuration.
  // Why? To allow the user to save an integration configuration.
  // How?
  // - Sets isSaving to true to show a spinner.
  // - Tries to save the integration configuration using updateIntegration or createIntegration.
  // - Updates the integrations in the state.
  // - Calls onSuccess if the integration configuration is saved successfully.
  // - Calls onError if there is an error saving the integration configuration.
  // - Sets isSaving to false after saving the integration configuration.
  const handleSaveConfig = useCallback(
    async (payload: Partial<Integration>, onSuccess: () => void, onError: (error: string) => void) => {
      if (!payload.type || !workspace_id) {
        setError('Integration type or workspace ID is missing.');
        onError('Integration type or workspace ID is missing.');
        return;
      }
  
      try {
        setIsSaving(true);
        let result: Integration;
  
        if (!isNewConfig) {
          result = await updateIntegration(payload.type, workspace_id, payload);
        } else {
          result = await createIntegration(payload.type, workspace_id, payload);
        }
  
        setIntegrations((prevIntegrations) =>
          prevIntegrations.map((intg) => {
            if (intg.type === payload.type) {
              return {
                ...intg,
                config: [payload],
                enabled: payload.enabled,
                configurable: true,
              };
            }
            return intg;
          })
        );
        onSuccess();
      } catch (error: any) {
        const errorMessage = error.response?.data || 'Failed to save integration. Please try again.';
        onError(errorMessage);
      } finally {
        setIsSaving(false);
      }
    },
    [updateIntegration, createIntegration, workspace_id, isNewConfig]
  );

  // What? A function to handle the deletion of an integration configuration.
  // Why? To allow the user to delete an integration configuration.
  // How?
  // - Sets the integration and configuration item in the state.
  // - Opens the confirmation modal.
  const handleDelete = useCallback((integration: Integration, configItem: any) => {
    const displayName = getDisplayValue(integration, configItem);
    setConfigToDelete({ integrationName: integration.name, configId: configItem.id, displayName });
    setConfirmationModalOpen(true);
  }, []);

  // What? A function to handle the confirmation of deleting an integration configuration.
  // Why? To allow the user to delete an integration configuration.
  // How?
  // - Sets isDeleting to true to show a spinner.
  // - Tries to delete the integration configuration using deleteIntegration.
  // - Updates the integrations in the state.
  // - Closes the confirmation modal.
  // - Resets the configToDelete in the state.
  const confirmDelete = useCallback(async () => {
    if (!configToDelete || !workspace_id) return;
  
    setIsDeleting(true);
    try {
      const integrationType = integrations.find(
        intg => intg.name === configToDelete.integrationName
      )?.type;
  
      if (!integrationType) {
        console.error('Integration type not found.');
        return;
      }
  
      await deleteIntegration(integrationType, workspace_id, configToDelete.integrationName);
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((intg) =>
          intg.name === configToDelete.integrationName
            ? {
                ...intg,
                config: intg.config?.filter((config: any) => config.id !== configToDelete.configId),
              }
            : intg
        )
      );
    } catch (error: any) {
      console.error('Failed to delete configuration. Please try again later.', error);
    } finally {
      setIsDeleting(false);
      setConfirmationModalOpen(false);
      setConfigToDelete(null);
    }
  }, [configToDelete, deleteIntegration, workspace_id, integrations]);
  
  // What? A function to get the display value of an integration configuration.
  // Why? To display the name of an integration configuration.
  // How?
  // - Gets the configureObjectType of the integration.
  // - Gets the configMeta of the integration.
  // - Gets the displayField of the integration.
  // - Gets the displayValue of the integration configuration.
  // - Returns the displayValue or 'Unnamed Configuration' if the displayValue is not found.
  const getDisplayValue = (integration: Integration, configItem: any) => {
    const configureObjectType = integration.configureObjectType;
  
    if (!configureObjectType) {
      return 'Unnamed Configuration';
    }
  
    const configMeta = IntegrationConfigMetadata[configureObjectType];
    const displayField = configMeta?.displayField;
  
    if (!displayField) {
      return 'Unnamed Configuration';
    }
  
    const displayValue = configItem[displayField];
  
    if (Array.isArray(displayValue)) {
      if (displayValue.length === 0) {
        return 'Unnamed Configuration';
      }
      return displayValue.length > 1
        ? `${displayValue[0]}, [...]`
        : displayValue[0];
    }
  
    return displayValue || 'Unnamed Configuration';
  };

  return (
    <div className="workspace-sources-container">
      <h1>Integrations and Sources</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Integration</th>
              <th>Connection</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan={3} style={{ color: 'red', textAlign: 'center' }}>
                  {error}
                </td>
              </tr>
            ) : integrations.length > 0 ? (
              integrations.map((integration) => (
                <React.Fragment key={integration.id}>
                  <tr className="integration-header">
                    <td>
                      <span className="integration-icon" style={{ marginRight: '10px' }}>
                        {integration.icon}
                      </span>
                      <span className="integration-name">{integration.name}</span>
                      <span className="integration-description">{integration.description}</span>
                    </td>
                    <td></td>
                    <td>
                    {integration.allowMultipleConfigs || !integration.config || integration.config.length === 0 ? (
                        <Button
                        className="btn-add primaryBtn"
                        onClick={() => handleConfigure(integration)}
                        >
                        Add
                        </Button>
                    ) : null}
                    </td>
                  </tr>
                  
                  {integration.config && Array.isArray(integration.config) && integration.config.length > 0 ? (
                    integration.config.map((configItem, index) => (
                    <tr key={index} className="integration-row">
                        <td></td>
                        <td>{getDisplayValue(integration, configItem)}</td>
                        <td>
                        <Button
                            className="btn-configure primaryBtn"
                            onClick={() => handleConfigure(integration, configItem)}
                        >
                            Configure
                        </Button>
                        <Button
                            className="btn-delete primaryBtn"
                            onClick={() => handleDelete(integration, configItem)}
                        >
                            Delete
                        </Button>
                        </td>
                    </tr>
                    ))
                ) : (
                    <tr>
                    <td></td>
                    <td>No configurations</td>
                    <td></td>
                    </tr>
                )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  No integrations found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
      {selectedIntegration && (
        <IntegrationModal
          open={modalOpen}
          integration={selectedIntegration}
          workspaceId={workspace_id!}
          onClose={handleModalClose}
          onSave={handleSaveConfig}
          error={error}
        />
      )}
      {confirmationModalOpen && configToDelete &&(
        <ConfirmationModal
            open={confirmationModalOpen}
            title="Delete Configuration"
            message={`Are you sure you want to delete the configuration (${configToDelete.displayName})?`}
            actionLabel="Delete"
            cancelLabel="Cancel"
            onAction={confirmDelete}
            onClose={() => setConfirmationModalOpen(false)}
            loading={isDeleting}
            spinner={<CircularProgress size="sm" />}
        />
      )}
    </div>
  );
};

export default WorkspaceSources;
