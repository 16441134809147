import React, { useEffect, useState } from 'react';
import { useGetResourceUsage } from '../../services/api';
import AddRequestsModal from '../Modals/AddRequestsModal'; // Import the modal component
import { Button } from '@mui/joy';
import { Link } from 'react-router-dom';
import '../../styles/WorkspaceSubscription.css';
import '../../styles/ResourceUsage.css';

const ResourceUsage: React.FC = () => {
  const getResourceUsage = useGetResourceUsage();
  const [usageData, setUsageData] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false); // Track modal state

  useEffect(() => {
    const fetchData = async () => {
      const data = await getResourceUsage();
      setUsageData(data);
    };
    fetchData();
  }, []);

  const openModal = () => {
    setModalOpen(true); // Open the modal when the button is clicked
  };

  if (!usageData) {
    return <div>Loading...</div>;
  }

  const getProgressBarClass = (percentage: number) => {
    if (percentage < 70) return 'resource-progress good';
    if (percentage < 90) return 'resource-progress warning';
    return 'resource-progress danger';
  };

  return (
    <div className="card card-split">
      <div className="card-content">
        <h2>Resource Usage</h2>
        {usageData.resources.map((resource: any, index: number) => (
          <div key={index}>
            <h3>
              {resource.name}: {resource.used}/{resource.total} used
            </h3>
            <div
              className="resource-bar"
              style={{
                background: 'var(--secondary-bg-color)',
                borderRadius: 'var(--border-radius-medium)',
                height: '16px',
                margin: 'var(--spacing-sm) 0',
                overflow: 'hidden',
              }}
            >
              <div
                className={getProgressBarClass(resource.percentage)}
                style={{
                  width: `${resource.percentage}%`,
                  height: '100%',
                  borderRadius: 'var(--border-radius-medium)',
                  transition: 'width 0.3s ease',
                  background:
                    resource.percentage < 70
                      ? 'var(--good-color)'
                      : resource.percentage < 90
                      ? 'var(--warning-color)'
                      : 'var(--bad-color)',
                }}
              ></div>
            </div>
            {resource.message &&
            <div className='message'>
              <p>{resource.message}</p>
            </div>
            }
          </div>
        ))}
        <button className="primaryBtn" onClick={openModal}>
          Add More Requests
        </button>
      </div>

      <div className="card-side-content">
        <p>Upgrade now for up to 10K requests, 1TB Storage, Unlimited Workspaces and more!</p>
        <Link to="/upgrade">
          <Button>See tiers</Button>
        </Link>
      </div>

      {/* Render the modal */}
      <AddRequestsModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default ResourceUsage;
