// src/views/WorkspaceFiles.tsx

// What? A React component for managing files in a workspace.
// Why? To allow the user to upload, delete, and view files in a workspace.
// How?
// - Fetches the workspace files using workspaceFiles.
// - Displays the files in a table.
// - Allows the user to upload files.
// - Allows the user to delete files.

import React, { useState, useEffect } from 'react';
import { useListFiles, useDeleteFile, useUploadFile, useWorkspaceRead } from '../services/api';
import { useParams } from 'react-router-dom';
import { Box, Table, Button as JoyButton, CircularProgress } from '@mui/joy';
import { FileType } from '../common/types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "../styles/WorkspaceFiles.css";
import UploadFileModal from '../components/Modals/UploadFileModal';
import ConfirmationModal from '../components/Modals/ConfirmationModal';

let tempIdCounter = 0;

const WorkspaceFiles = () => {
  const workspaceFiles = useListFiles();
  const deleteFile = useDeleteFile();
  const uploadFile = useUploadFile();
  const { workspace_id } = useParams() as { workspace_id: string };
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const viewName = "Files"; // Change this accordingly for each view

  useEffect(() => {
    const storedWorkspaceName = localStorage.getItem('workspaceName');
    if (storedWorkspaceName) {
      setWorkspaceName(storedWorkspaceName);
    }
  }, []);

  const title = workspaceName ? `${workspaceName} > ${viewName}` : viewName;

  // What? A function to fetch the workspace files from the API.
  // Why? To populate the workspace files in the component.
  // How?
  // - Sets loading to true to show a spinner.
  // - Tries to fetch the workspace files using workspaceFiles.
  // - Updates the workspace files in the state.
  // - Sets loading to false after fetching the data.
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await workspaceFiles(workspace_id);
      if (data) {
        setFiles(data);
      }
    } catch (error) {
      console.error('Failed to fetch files', error);
    } finally {
      setLoading(false);
    }
  };

  // What? A function to handle the deletion of a file.
  // Why? To allow the user to delete a file from the workspace.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Tries to delete the file using deleteFile.
  // - Updates the workspace files in the state.
  // - Closes the delete modal.
  // - Sets isProcessing to false after deletion.
  const handleDelete = async () => {
    setIsProcessing(true);
    try {
      await deleteFile(workspace_id, fileId);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      setDeleteOpen(false);
    } catch (error) {
      console.error('Failed to delete file', error);
    } finally {
      setIsProcessing(false);
    }
  };

  // What? A function to handle the upload of files.
  // Why? To allow the user to upload files to the workspace.
  // How?
  // - Closes the upload modal immediately.
  // - Maps the existing files in the state.
  // - Creates new uploading files with temporary IDs.
  // - Updates the files in the state with the new uploading files.
  // - Uploads each file using uploadFile.
  // - Updates the files in the state with the uploaded files.
  const handleFilesUpload = async (uploadedFiles: File[], maxParallelUploads = 3) => {
    closeUploadModal();
    
    const existingFileMap = new Map(files.map(file => [file.name, file]));
  
    const newUploadingFiles: FileType[] = uploadedFiles.map((file) => {
      if (existingFileMap.has(file.name)) {
        const existingFile = existingFileMap.get(file.name);
        return {
          ...existingFile,
          id: existingFile!.id,
          isUploading: true,
        } as FileType;
      }
  
      const tempId = `temp-${Date.now()}-${tempIdCounter++}`;
      return {
        id: tempId,
        name: file.name,
        path: '',
        node_type: 'file',
        last_modified: null,
        isUploading: true,
      };
    });
  
    setFiles((prevFiles) => {
      // Filter out the replaced files from the previous list (to avoid duplicates)
      const filteredFiles = prevFiles.filter(file => !uploadedFiles.some(upFile => upFile.name === file.name));
      return [...filteredFiles, ...newUploadingFiles];
    });
  
    const uploadQueue = [...uploadedFiles];
    let activeUploads = 0;
  
    const processNext = () => {
      if (uploadQueue.length === 0 && activeUploads === 0) {
        return;
      }
  
      while (activeUploads < maxParallelUploads && uploadQueue.length > 0) {
        const file = uploadQueue.shift();
        const tempFile = newUploadingFiles.find((f) => f.name === file?.name);
        const tempId = tempFile?.id;
  
        if (file) {
          activeUploads += 1;
          uploadFile(workspace_id, file)
            .then((uploadedFile) => {
              setFiles((prevFiles) =>
                prevFiles.map((f) =>
                  f.id === tempId
                    ? {
                        ...f,
                        id: uploadedFile.id,
                        path: uploadedFile.path,
                        last_modified: uploadedFile.last_modified,
                        isUploading: false,
                      }
                    : f
                )
              );
            })
            .catch((error) => {
              console.error(`Failed to upload file ${file.name}`, error);
            })
            .finally(() => {
              activeUploads -= 1; 
              processNext(); 
            });
        }
      }
    };
  
    processNext();
  };
  

  const openUploadModal = () => setModalOpen(true);
  const closeUploadModal = () => setModalOpen(false);

  useEffect(() => {
    if (workspace_id) {
      fetchData();
    }
  }, [workspace_id]);

  return (
    <Box sx={{ display: 'flex' }} className="tableBox">
      <Box component="main">
        <div className="headerContainer">
          <span className="title">{title}</span>
          <JoyButton
            variant="solid"
            startDecorator={<CloudUploadIcon style={{ marginRight: '8px' }} />}
            onClick={openUploadModal}
            className="primaryBtn"
          >
            Upload file
          </JoyButton>
        </div>

        <Table sx={{ '& thead th:nth-child(1)': { width: '40%' } }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Path</th>
              <th>Delete</th>
              <th>Uploaded At</th>
            </tr>
          </thead>
          <tbody>
            {loading && files.length === 0 ? (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  <CircularProgress size="lg" className="customSpinner" />
                </td>
              </tr>
            ) : files.length > 0 ? (
              files.map((file) => (
                <tr key={file.id}>
                  <td className="workspaceName">{file.name}</td>
                  <td>{file.path}</td>
                  <td>
                    {file.isUploading ? (
                      <DeleteOutlineIcon style={{ opacity: 0.5, cursor: 'not-allowed' }} />
                    ) : (
                      <div
                        className="deleteFile"
                        onClick={() => {
                          setFileId(file.id);
                          setDeleteOpen(true);  // Open delete modal
                        }}
                      >
                        <DeleteOutlineIcon />
                      </div>
                    )}
                  </td>
                  <td>
                    {file.isUploading ? (
                      <CircularProgress size="sm" className="customSpinner" />
                    ) : file.last_modified ? (
                      file.last_modified.toLocaleString()
                    ) : (
                      'Unknown'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No files available</td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Upload modal */}
        <UploadFileModal
          open={modalOpen}
          onClose={closeUploadModal}
          handleFilesUpload={(files) => handleFilesUpload(files, 3)} // 3 parallel uploads
          existingFiles={files.map(file => file.name)}  
        />

        {/* Confirmation Modal for deleting files */}
        <ConfirmationModal
          open={deleteOpen}
          title="Delete File"
          message="Are you sure you want to delete this file? This action cannot be undone."
          actionLabel="Delete"
          cancelLabel="Cancel"
          onAction={handleDelete}
          onClose={() => setDeleteOpen(false)}
          loading={isProcessing}
          spinner={<CircularProgress size="sm" className="customSpinner" />}
        />
      </Box>
    </Box>
  );
};

export default WorkspaceFiles;
