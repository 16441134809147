// /src/components/Modals/AddRequestsModal.tsx

// What? A modal for selecting a request package to purchase.
// Why? To allow users to purchase additional requests for their account.
// How?
// - Uses a Modal component to display the modal.
// - Uses a ModalDialog component to display the modal content.
// - Uses a useGetRequestPackages hook to fetch the request packages.
// - Uses a useAddMoreRequests hook to add more requests to the user's account.
// - Uses a useState to manage the state of the modal.
// - Uses a useEffect to fetch the request packages when the modal is opened.
// - Uses a handlePurchase function to add more requests to the user's account when a package is selected.
// - Uses a handleClose function to close the modal when the purchase is done.

// TODO: Remove this and replace with the builtin Stripe checkout.

import React, { useEffect, useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useGetRequestPackages, useAddMoreRequests } from '../../services/api';
import '../../styles/AddRequestsModal.css';

interface AddRequestsModalProps {
  open: boolean;
  onClose: () => void;
}

const AddRequestsModal: React.FC<AddRequestsModalProps> = ({ open, onClose }) => {
  const getRequestPackages = useGetRequestPackages();
  const addMoreRequests = useAddMoreRequests();
  const [packages, setPackages] = useState<any[]>([]);

  useEffect(() => {
    const fetchPackages = async () => {
      const data = await getRequestPackages();
      const sortedPackages = data.sort((a: any, b: any) => a.amount - b.amount); // Sort by amount, smallest first
      setPackages(sortedPackages);
    };
    fetchPackages();
  }, []);

  const handlePurchase = async (pkg: any) => {
    await addMoreRequests(pkg.amount);
    onClose(); // Close the modal after the purchase is done
  };

  if (!packages.length) {
    return <div>Loading...</div>;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog className="modal">
        <h2>Select a Request Package</h2>
        <div className="request-packages-horizontal">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className="request-package-card"
              onClick={() => handlePurchase(pkg)}
            >
              <div className="package-info">
                <h3>{pkg.amount}<br />Requests</h3>
                <p>€{pkg.price}</p>
              </div>
              {pkg.discount > 0 && (
                <div className={`rosette-badge ${pkg.discount >= 20 ? 'large-discount' : 'small-discount'}`}>
                    <svg className="rosette-svg" viewBox="0 0 200 200" width="150" height="150">
                        <circle cx="100" cy="100" r="65" fill="none" stroke="currentColor" strokeWidth="10"/>
                        <circle cx="100" cy="100" r="55" fill="currentColor"/>
                        <text x="100" y="90" textAnchor="middle" fill="#fff" fontSize="32" fontWeight="bold">
                        <tspan x="100" dy="0">{pkg.discount}%</tspan>
                        <tspan x="100" dy="1.25em">Off</tspan>
                        </text>
                    </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default AddRequestsModal;