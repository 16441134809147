// /src/components/WorkspaceSidebar.tsx

// What? A component for the workspace sidebar.
// Why? To display the workspace sidebar with navigation items.
// How?
// - Uses the Box component from MUI Joy to create a flexible container for the sidebar
// - Implements a collapsible sidebar using React state (isCollapsed) and CSS classes
// - Renders a toggle button to expand/collapse the sidebar for better space management
// - Utilizes the List component to structure the navigation items in a vertical layout
// - Employs ListItem and ListItemButton components to create interactive navigation elements
// - Integrates icons (e.g., HomeIcon, ArrowBackIcon) to visually represent each navigation item
// - Leverages React Router's useParams, useLocation, and useNavigate hooks for dynamic routing
// - Applies conditional CSS classes to highlight the active navigation item based on the current route
// This implementation creates a responsive, collapsible sidebar that provides easy navigation
// within the workspace, fulfilling the component's purpose of displaying an interactive
// and user-friendly workspace sidebar.

import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, List, ListItem, ListItemButton } from '@mui/joy';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CachedIcon from '@mui/icons-material/Cached';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChatIcon from '@mui/icons-material/Chat';
import FolderIcon from '@mui/icons-material/Folder';
import SourcesIcon from '@mui/icons-material/Polyline'

import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../providers/WorkspaceProvider'; // Import role-based context

import '../styles/WorkspaceSidebar.css';

const WorkspaceSidebar: React.FC = () => {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOwner, isAdmin, isEditor } = useWorkspaceContext();

  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar collapse state
  
  const workspaceName = localStorage.getItem('workspaceName');
  const workspaceNameDiv = useRef<HTMLDivElement>(null);  // Reference for workspaceName div

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);  // Toggle the collapse state
  };
  
  // Dynamically change the sidebar width
  useEffect(() => {
    var width = '0';
    if(workspace_id) {
      width = isCollapsed ? '50px' : '150px';
    }
    document.documentElement.style.setProperty('--sidebar-width', width);
  }, [workspace_id, isCollapsed]);

  // Adjust workspace name's font size to fit the available space
  useEffect(() => {
    const adjustFontSizeToFit = (element: HTMLDivElement | null) => {
      if (!element) return;
      let fontSize = parseFloat(window.getComputedStyle(element).fontSize);

      // Shrink font size if a word is too long and still exceeds the container's width
      while (element.scrollWidth > element.offsetWidth && fontSize > 5) {
        fontSize -= 1;
        element.style.fontSize = `${fontSize}px`;
      }
    };

    adjustFontSizeToFit(workspaceNameDiv.current);  // Adjust the font size of workspaceName
  }, [workspaceName]);

  return (
    <>
      <Box className="sidebar">
        {/* Sidebar toggle button */}
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect x="2" y="2" width="20" height="20" rx="2" ry="2" fill="none" stroke="currentColor" strokeWidth="2"/>
              <line x1="9" y1="2" x2="9" y2="22" stroke="currentColor" strokeWidth="2"/>
              <circle cx="5.5" cy="7" r="1.5" fill="currentColor"/>
              <circle cx="5.5" cy="12" r="1.5" fill="currentColor"/>
              <circle cx="5.5" cy="17" r="1.5" fill="currentColor"/>
          </svg>
        </div>

        {/* Navigation */}
        <nav aria-label="sidebar navigation">
          <List className={`sidebar-content ${isCollapsed ? 'hidden' : ''}`}>
            <Divider/>
            {workspaceName &&
              <>
                <div className="workspace-name-heading">
                  Workspace
                </div>
                <div className="workspace-name" ref={workspaceNameDiv}>
                  {workspaceName}
                </div>
                <Divider/>
              </>
            }
            <ListItem className={`${location.pathname === '' ? 'activeTab' : ''}`}>
              <ListItemButton component="a" href="/">
                <ArrowBackIcon className={`${location.pathname === '' ? 'activeIcon' : ''}`} />
                <div>Back</div>
              </ListItemButton>
            </ListItem>

            {/* Main Workspace: Visible only to Owners */}
            {isOwner && (
              <ListItem className={`${location.pathname === `/workspace/${workspace_id}` ? 'activeTab' : ''}`}>
                <ListItemButton component="a" href={workspace_id ? `/workspace/${workspace_id}` : '/workspace/'}>
                  <HomeIcon className={`${location.pathname === `/workspace/${workspace_id}` ? 'activeIcon' : ''}`} />
                  <div>Main</div>
                </ListItemButton>
              </ListItem>
            )}

            {/* Users and Sources: Visible to Admins and Owners */}
            {isAdmin && (
              <>
                <ListItem className={`${location.pathname === `/workspace/${workspace_id}/users` ? 'activeTab' : ''}`}>
                  <ListItemButton component="a" href={`/workspace/${workspace_id}/users`}>
                    <PeopleAltIcon className={`${location.pathname === `/workspace/${workspace_id}/users` ? 'activeIcon' : ''}`} />
                    <div>Users</div>
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton component="a" href={`/workspace/${workspace_id}/sources`}>
                    <SourcesIcon />
                    <div>Sources</div>
                  </ListItemButton>
                </ListItem>
              </>
            )}

            {/* Files Tab: Visible to Editors, Admins, and Owners */}
            {isEditor && (
              <ListItem className={`${location.pathname === `/workspace/${workspace_id}/files` ? 'activeTab' : ''}`}>
                <ListItemButton component="a" href={`/workspace/${workspace_id}/files`}>
                  <FolderIcon className={`${location.pathname === `/workspace/${workspace_id}/files` ? 'activeIcon' : ''}`} />
                  <div>Files</div>
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default WorkspaceSidebar;
