// src/views/WorkspaceSubscription.tsx

// What? A React component for managing the workspace subscription.
// Why? To allow the user to manage their workspace subscription.
// How?
// - Fetches the current tier using getCurrentTier.
// - Displays the current tier card, resource usage, usage trends chart, billing info, billing history, and tier comparison.
// - Passes the current tier ref to the CurrentTierCard component.

// TODO: Consider removing this and replacing with the Stripe integration.

import React, { useRef } from 'react';
import CurrentTierCard from '../components/Subscription/CurrentTierCard';
import ResourceUsage from '../components/Subscription/ResourceUsage';
import UsageTrendsChart from '../components/Subscription/UsageTrendsChart';
import IntegrationsList from '../components/Subscription/IntegrationsList';
import BillingInfo from '../components/Subscription/BillingInfo';
import BillingHistory from '../components/Subscription/BillingHistory';
import TierComparison from '../components/Subscription/TierComparison';
import '../styles/WorkspaceSubscription.css';

const WorkspaceSubscription: React.FC = () => {
  const currentTierRef = useRef<HTMLDivElement>(null); // Create a ref for the current tier card

  return (
    <div className="subscription-page">
      <h1 className="title">Subscription Management</h1>
      <div className="subscription-content">
        {/* Pass the currentTierRef to CurrentTierCard */}
        <CurrentTierCard currentTierRef={currentTierRef} />
        <ResourceUsage />
        <UsageTrendsChart />
        <BillingInfo />
        <BillingHistory />
        {/* Pass the currentTierRef to TierComparison */}
        <TierComparison currentTierRef={currentTierRef} />
      </div>
    </div>
  );
};

export default WorkspaceSubscription;