import React, { useEffect, useState, useRef } from 'react';
import { useGetSubscriptionDetails } from '../../services/api';
import '../../styles/CurrentTierCard.css';

const CurrentTierCard: React.FC<{ currentTierRef: React.RefObject<HTMLDivElement> }> = ({ currentTierRef }) => {
  const getSubscriptionDetails = useGetSubscriptionDetails();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSubscriptionDetails();
      setSubscriptionDetails(data);
    };
    fetchData();
  }, []);

  const handleUpgradeClick = () => {
    const tierComparisonElement = document.getElementById('tier-comparison');
    const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height')); // Get the top bar height
    const currentTierElement = document.querySelector('.current-tier'); // Locate the current tier element
    
    if (currentTierElement) {
      const offsetTop = currentTierElement.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' }); // Scroll with the calculated offset
    }
  };

  if (!subscriptionDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card">
      <h2>Current Tier: {subscriptionDetails.currentTier}</h2>
      <p>
        €{subscriptionDetails.monthlyPrice}/month (€{subscriptionDetails.yearlyPrice}/year - save 2 months!)
      </p>
      <ul>
        {subscriptionDetails.benefits.map((benefit: string, index: number) => (
          <li key={index}>{benefit}</li>
        ))}
      </ul>
      <button className="primaryBtn" onClick={handleUpgradeClick}>Upgrade Tier</button>
    </div>
  );
};

export default CurrentTierCard;