// src/utils/useAuthFetch.ts
// What? A custom hook for making authenticated API requests.
// Why? To handle different authentication methods (Auth0, EntraID, and unauthenticated).
// How?
// - Uses the useAuth0 hook for Auth0 authentication.
// - Uses the useMsal hook for EntraID authentication.
// - Provides a default implementation for unauthenticated requests.
// - Supports different response types (JSON or raw response).
// - Logs errors and displays them to the user.
import { useAuth0 } from '@auth0/auth0-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useUnsafeAuthProvider } from "../components/UnsafeAuthProvider"
import { useErrorMessageProvider } from '../components/ErrorMessageProvider'

// What? A custom hook for making authenticated API requests.
// Why? To handle different authentication methods (Auth0, EntraID, and unauthenticated).
// How?
// - Uses the useAuth0 hook for Auth0 authentication.
// - Uses the useMsal hook for EntraID authentication.
// - Provides a default implementation for unauthenticated requests.
// - Supports different response types (JSON or raw response).
// - Logs errors and displays them to the user.
export const useAuthFetch = (response_type: string = "json") => {
  const { getAccessTokenSilently } = useAuth0();
  const { instance, accounts, inProgress } = useMsal();
  const { unsafeUserName } = useUnsafeAuthProvider();
  const { registerError } = useErrorMessageProvider();
  const isAuthenticated = useIsAuthenticated();

  // What? A helper function to add a query parameter to an endpoint.
  // Why? To handle different authentication methods (Auth0, EntraID, and unauthenticated).
  // How?
  // - Uses the URL constructor to create a URL object.
  // - Checks if the URL already has the query parameter.
  // - Appends the query parameter if it doesn't exist.
  // - Returns the modified endpoint.
  function addQueryParam(endpoint: string, param: string, value: string): string {
    const urlObj = new URL(endpoint, window.location.origin);
    if (!urlObj.searchParams.has(param)) {
      urlObj.searchParams.append(param, value);
    }
    return urlObj.pathname + urlObj.search;
  }

  // What? A helper function to make an unauthenticated API request.
  // Why? To handle unauthenticated requests.
  // How?
  // - Adds the user_name query parameter to the endpoint.
  // - Calls the defaultFetch function with the modified endpoint.
  // - Returns the response from the API call.
  const unsafeFetch = async (endpoint: string, tag: string, options: RequestInit = {}, timeout = 15000) => {
    endpoint = addQueryParam(endpoint, "user_name", unsafeUserName)
    return await defaultFetch(endpoint, tag, options, timeout)
  }

  // What? A helper function to make an authenticated API request using Auth0.
  // Why? To handle authenticated requests using Auth0.
  // How?
  // - Uses the getAccessTokenSilently function to get the access token.
  // - Adds the access token to the Authorization header.
  // - Calls the defaultFetch function with the modified endpoint.
  // - Returns the response from the API call.
  const auth0Fetch = async (endpoint: string, tag: string, options: RequestInit = {}, timeout = 15000) => {
    const token = await getAccessTokenSilently();
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    return await defaultFetch(endpoint, tag, { ...options, headers }, timeout);
  }

  // What? A helper function to make an authenticated API request using EntraID.
  // Why? To handle authenticated requests using EntraID.
  // How?
  // - Uses the acquireTokenSilent function to get the access token.
  // - Adds the access token to the Authorization header.
  // - Calls the defaultFetch function with the modified endpoint.
  // - Returns the response from the API call.
  const entraIDFetch = async (endpoint: string, tag: string, options: RequestInit = {}, timeout = 15000) => {
    const getAccessToken = async (): Promise<string> => {
      const request = {
        scopes: ["api://0501f640-94a0-4fd9-a7ae-0e8fa5938130/access_as_user", "email", "openid", "profile"],
        account: accounts[0],
      };

      await instance.initialize();
      
      try {
        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          if (inProgress === InteractionStatus.None) {
            instance.loginRedirect(request);
          }

          return await getAccessToken()
        }
        else {
          throw error;
        }
      }
    };

    const token = await getAccessToken();

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    return await defaultFetch(endpoint, tag, { ...options, headers }, timeout);
  }

  // What? A helper function to make an API request.
  // Why? To handle API requests.
  // How?
  // - Constructs the full URL using the API URL and the endpoint.
  // - Creates an AbortController to handle request timeout.
  // - Tries to parse the response as JSON.
  // - Throws an error if the response is not OK.
  // - Handles 204 No Content responses separately.
  // - Returns the response based on the response type.
  const defaultFetch = async (endpoint: string, tag: string, options: RequestInit = {}, timeout = 15000) => {
    const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });

      if (!response.ok) {
        // Try parsing error body as JSON if available
        let errorData = {"detail": "Unknown error"};
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          errorData = await response.json();
        }

        // Attach additional properties to the Error object
        const error = new Error(`HTTP Error: status: ${response.status} (${response.statusText})`);
        (error as any).response = errorData;      // Attach error response
        (error as any).status = response.status;  // Attach status code

        throw error;
      }

      // Handle 204 No Content separately (don't try to parse response)
      if (response.status === 204) {
        return null;
      }
        
      if (response_type === "json") {
        return await response.json();
      }
      return response;
    } catch (error: any) {
      // Show error to frontend
      const action_tag: string = (tag === "")? "API Error" : `Failed to ${tag}`;
      const error_detail: string = (error.name == "AbortError")? "Server did not respond. Please try again." : Object.values(error.response).join(' ');
      registerError(`${action_tag}: ${error_detail}`);
      console.error(error, `${action_tag}: ${error_detail}`)

      throw error
    } finally {
      clearTimeout(id);
    }
  }

  if (process.env.REACT_APP_API_URL === "http://localhost:3001")
    return unsafeFetch;
  if (process.env.REACT_APP_AUTHENTICATION_METHOD === "AUTH0")
    return auth0Fetch;
  if (process.env.REACT_APP_AUTHENTICATION_METHOD === "ENTRAID")
    return entraIDFetch;
  return unsafeFetch;
};