// src/views/UserUpgrade.tsx
import React, { Suspense, useEffect, useState } from 'react';
import '../styles/WorkspaceSubscription.css';
import { Box, Button, Card, CircularProgress, Divider } from '@mui/joy';
import { useGetAvailableTiers } from '../services/api';
import { Person, People, Groups, Language, Subscript } from '@mui/icons-material'; // <- New icons must be added here (1/2)

const UserStats: React.FC = () => {
  const getAvailableTiers = useGetAvailableTiers();
  const [tiers, setTiers] = useState<any[]>([]);

  // Dynamically load and map icons
  const iconMap: { [key: string]: React.ElementType } = { Person, People, Groups, Language, };

  // Function to load icons with optional font size
  const loadIcon = (iconName: string, size: string = '64px') => {
    const IconComponent = iconMap[iconName];
    if (IconComponent) {
      return <IconComponent style={{ fontSize: size }} />;  // Use inline style for size
    }
    console.error(`Icon '${iconName}' not found!`);
    return null;
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await getAvailableTiers();
      setTiers(data);
    };
    fetchData();
  }, []);

  if (!tiers.length) {
    return <div><CircularProgress/></div>;
  }

  return (
    <div className="page-centered">
      <h1 className="page-title">Subscription Tiers</h1>
      <Box className="content-box" sx={{ display: 'flex', justifyContent: 'center', whiteSpace:'nowrap' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', gap: 0 }}>
          {tiers.map((tier, index) => (
            <React.Fragment key = {index}>
              {/* Column */}
              <div style={{ textAlign: 'center', height: '100%', }}>
                {/* Header row */}
                <div style={{ height: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', padding:'0 8px'}}>
                  <div>
                    <Suspense fallback={<div><CircularProgress/></div>}>
                      {loadIcon(tier.iconName, 'xx-large')}
                    </Suspense>
                  </div>
                  <div>
                    {tier.name}
                  </div>
                </div>
                {/* Price row */}
                <div style={{ height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '20px', padding:'0 8px'}}>
                  <div style={{fontSize: 'x-small', color: 'var(--secondary-color)'}}>
                    {tier.price}
                  </div>
                  { tier.priceYearlyBilling &&
                    <div style={{fontSize: 'x-small', color: 'var(--secondary-color)'}}>
                      Yearly plan: {tier.priceYearlyBilling}
                    </div>
                  }
                </div>
                <Divider />

                {/* Feature rows */}
                {tier.features.map((feature:any, featureIndex:any) => (
                  <React.Fragment key={featureIndex}>
                    <div style={{ height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 'small', padding:'0 8px' }}>
                      {feature}
                    </div>
                    <Divider />
                  </React.Fragment>
                ))}

                {/* Button row */}
                <div style={{ height:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '8px 4px', fontSize: 'small' }}>
                  {tier.isCurrent ? (
                    <div style={{ color: 'var(--secondary-color)' }}>Current plan</div>
                  ) : (
                    <Button sx={{ width: '100%', height: '100%' }}>{tier.actionLabel}</Button>
                  )}
                </div>
              </div>
              {index < tiers.length - 1 && <Divider orientation="vertical" sx={{ margin: 0, padding: 0 }} />}

            </React.Fragment>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default UserStats;