// src/views/WorkspaceSubscription.tsx
import React, { useRef } from 'react';
import BillingInfo from '../components/Subscription/BillingInfo';
import BillingHistory from '../components/Subscription/BillingHistory';
import '../styles/WorkspaceSubscription.css';

const UserBilling: React.FC = () => {
  const currentTierRef = useRef<HTMLDivElement>(null); // Create a ref for the current tier card

  return (
    <div className="subscription-page">
      <h1 className="title">Billing</h1>
      <div className="subscription-content">
        <BillingInfo />
        <BillingHistory />
      </div>
    </div>
  );
};

export default UserBilling;