// What? A React component for managing the workspace users.
// Why? To allow the user to manage the users in their workspace.
// How?
// - Fetches the list of users in the workspace using workspaceUserListAPI.
// - Displays the list of users in a table.
// - Allows the user to add new users to the workspace.
// - Allows the user to remove users from the workspace.
// - Allows the user to promote users to different roles.

import React, { useState, useEffect } from 'react';
import { useWorkspaceUserList, useWorkspaceUserRemove, useWorkspaceUserInviteSend, useWorkspaceUserUpdate, useWorkspaceRead } from '../services/api';
import { useParams } from 'react-router-dom';
import { Box, Button, Table, CircularProgress } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import { WorkspaceUser } from '../common/types'; 
import Tooltip from '../components/Tooltip';
import AddUserModal from '../components/Modals/AddUserModal';
import ConfirmationModal from '../components/Modals/ConfirmationModal'; // Import the new ConfirmationModal
import { useWorkspaceContext } from '../providers/WorkspaceProvider';
import "../styles/StatusBadge.css";
import "../styles/WorkspaceUsers.css";

const WorkspaceUsers = () => {
  const workspaceUserListAPI = useWorkspaceUserList();
  const removeWorkspaceUserApi = useWorkspaceUserRemove();
  const addNewUser = useWorkspaceUserInviteSend();
  const promoteUser = useWorkspaceUserUpdate();

  const { workspace_id } = useParams() as { workspace_id: string };
  const fetchWorkspaceRead = useWorkspaceRead();  // Hook that returns the function to fetch workspace data
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [users, setUsers] = useState<WorkspaceUser[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<null | string>(null);
  const [creatingUser, setCreatingUser] = useState<boolean>(false);  // Spinner for adding user
  const [loading, setLoading] = useState<boolean>(false);  // Spinner for loading data from backend
  const [deleteUserOpen, setDeleteUserOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);  // Track which user is being deleted
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);  // Track loading for user deletion
  const [promotingUserId, setPromotingUserId] = useState<string | null>(null);  // Track user being promoted
  const viewName = "Users"; 

  const { isOwner, isOnlyOwner } = useWorkspaceContext();

  // What? A useEffect hook to get the workspace name from localStorage.
  // Why? To set the workspace name in the state.
  // How?
  // - Gets the workspace name from localStorage.
  // - Sets the workspace name in the state.
  useEffect(() => {
    // Get the workspace name from localStorage
    const storedWorkspaceName = localStorage.getItem('workspaceName');
    if (storedWorkspaceName) {
      setWorkspaceName(storedWorkspaceName);
    }
  }, []);

  const title = workspaceName ? `${workspaceName} > ${viewName}` : viewName;

  // What? A function to handle the submission of a new user form.
  // Why? To add a new user to the workspace.
  // How?
  // - Prevents default form submission behavior.
  // - Extracts user email and role from the form data.
  // - Calls addNewUser to create the new user.
  // - Refreshes the user list after creation.
  // - Sets creatingUser to false after creation.
  // - Closes the modal after creation.
  const handleAddUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const formData = new FormData(event.currentTarget);
    const userEmail = formData.get('userEmail') as string;
    const userRole = formData.get('role') as string;
  
    setCreatingUser(true);  // Start the spinner
    try {
      await addNewUser(workspace_id, userEmail, userRole || 'user'); // Call API to add user
      await fetchData(); // Refresh data after the user is added
    } catch (error) {
      console.error('Failed to add user', error);
    } finally {
      setCreatingUser(false);  // Stop the spinner
      setOpen(false);  // Close the modal
    }
  };

  // What? A function to open the delete user modal.
  // Why? To allow the user to delete a user from the workspace.
  // How?
  // - Sets the user to delete in the state.
  // - Opens the delete user modal.
  const openDeleteUserModal = (userId: string) => {
    setUserToDelete(userId);
    setDeleteUserOpen(true);
  };

  // What? A function to handle the confirmation of deleting a user.
  // Why? To allow the user to delete a user from the workspace.
  // How?
  // - Sets isDeletingUser to true to show a spinner.
  // - Tries to delete the user using removeWorkspaceUserApi.
  // - Refreshes the user list after deletion.
  // - Sets isDeletingUser to false after deletion.
  // - Closes the delete user modal.
  const handleDeleteUserConfirmed = async () => {
    setIsDeletingUser(true);  // Start loading spinner for delete action
    try {
      if (userToDelete) {
        await removeWorkspaceUserApi(workspace_id, userToDelete);  // API call to delete the user
        await fetchData();  // Refresh the list after deletion
      }
    } catch (error) {
      console.error('Failed to delete user', error);
    } finally {
      setIsDeletingUser(false);  // Stop loading spinner for delete action
      setDeleteUserOpen(false);  // Close the modal
    }
  };

  // What? A function to handle the promotion of a user.
  // Why? To allow the user to promote/demote a user to a different role.
  // How?
  // - Sets promotingUserId to show a spinner for the user being promoted.
  // - Tries to promote the user using promoteUser.
  // - Updates the user's role in the list if the promotion is successful.
  // - Clears the promotingUserId after the backend responds.
  // - Closes the tooltip.
  const handlePromote = async (role: string, userId: string) => {
    setPromotingUserId(userId);  // Show spinner for the user being promoted
    try {
      const updatedUser = await promoteUser(workspace_id, role, userId);  // Call the API to promote the user
  
      // If the backend returns the updated role, update the user's role in the list
      if (updatedUser && updatedUser.role) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.user_id === userId ? { ...user, role: updatedUser.role } : user
          )
        );
      }
    } catch (error) {
      console.error('Failed to promote user', error);
    } finally {
      setPromotingUserId(null);  // Clear the spinner after backend responds
      setTooltipOpen(null);  // Close tooltip
    }
  };
  
  // What? A function to fetch the list of users in the workspace.
  // Why? To display the list of users in the workspace.
  // How?
  // - Sets loading to true to show a spinner.
  // - Tries to fetch the list of users using workspaceUserListAPI.
  // - Sets the users in the state if the fetch is successful.
  // - Sets loading to false after fetching data.
  const fetchData = async () => {
    try {
      setLoading(true);  // Start the spinner when fetching data
      const data = await workspaceUserListAPI(workspace_id);
      if (data) {
        setUsers(data);
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    } finally {
      setLoading(false);  // Stop the spinner after fetching data
    }
  };

  useEffect(() => {
    if (workspace_id) { 
      fetchData();
    }
  }, [workspace_id]);

  const handleTooltipClose = () => {
    setTooltipOpen(null);
  };

  const handleTooltipClick = (userId: string) => {
    setTooltipOpen(tooltipOpen === userId ? null : userId);
  };

  const canDemote = (user: WorkspaceUser) => {
    if (isOwner && user.role === "owner" && user.status === "accepted") {
      return !isOnlyOwner
    }
    else if (!isOwner && user.role === "owner") {
      return false
    }
    return true
  }

  // What? A function to get the badge class for the user's status.
  // Why? To display the user's status in the table.
  // How?
  // - Returns the appropriate badge class based on the user's status.
  const getStatusBadgeClass = (status: 'Accepted' | 'Invited' | 'Inactivated' | string): string => {
    switch (status) {
      case 'accepted':
        return 'active-badge';
      case 'invited':
        return 'pending-badge';
      case 'inactivated':
        return 'alert-badge';
      default:
        return 'inactive-badge'; // Fallback class for unknown statuses
    }
  };

  // What? A conditional rendering to display a message if the workspace ID is not specified.
  // Why? To prevent the component from crashing if the workspace ID is not specified.
  // How?
  // - Returns a div with the message "Workspace not specified." if the workspace ID is not specified.
  if (!workspace_id) {
    return <div>Workspace not specified.</div>;
  }

  return (
    <Box sx={{ display: 'flex' }} className="tableBox">
      <Box component="main">
        <div className='headerContainer'>
          <span className='title'>{title}</span>
  
          {!loading && (
            <Button className='primaryBtn' onClick={() => setOpen(true)} startDecorator={<Add />}>
              Add new
            </Button>
          )}
        </div>
        
          {/* Show a spinner below the title while the data is loading */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <CircularProgress size="lg" className="customSpinner" />
            </Box>
          ) : (
          <Table sx={{ '& thead th:nth-child(1)': { width: '40%' } }}>
            <thead>
              <tr>
                <th>User</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_id}>
                  <td className="userName">
                    {user.email}
                  </td>
                  <td>
                    {promotingUserId === user.user_id ? (
                      <CircularProgress size="sm" className="customSpinner"/>
                    ) : (
                      user.role
                    )}
                  </td>
                  <td className="status-column">
                    <span className={`status-badge ${getStatusBadgeClass(user.status)}`}>
                      {user.status}
                    </span>
                  </td>
                  <td>
                    <Tooltip
                      content={
                        <Box className="contextMenu boxShadow">
                          {canDemote(user) && <Box className="contextMenuOption" sx={{ mb: 1, cursor: 'pointer' }} onClick={() => handlePromote('admin', user.user_id)} title="Admin: Can invite, remove and promote users.">Promote: Admin</Box>}
                          {canDemote(user) && <Box className="contextMenuOption" sx={{ mb: 1, cursor: 'pointer' }} onClick={() => handlePromote('editor', user.user_id)} title="Editor: Can upload and remove files.">Promote: Editor</Box>}
                          {canDemote(user) && <Box className="contextMenuOption" sx={{ cursor: 'pointer' }} onClick={() => handlePromote('user', user.user_id)} title="User: Can chat and interact with the bot.">Promote: User</Box>}
                          {canDemote(user) && <Box className="contextMenuOption" sx={{ cursor: 'pointer' }} onClick={() => openDeleteUserModal(user.user_id)} title="Remove the user from this workspace.">Remove</Box>}
                        </Box>
                      }
                      onClose={handleTooltipClose}
                    >
                      <Box sx={{ p: 2, bgcolor: 'primary.main', color: 'white', borderRadius: '4px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTooltipClick(user.user_id);
                        }}
                      >
                        Action
                      </Box>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Box>
  
      {/* Add User Modal */}
      <AddUserModal
        open={open}
        handleAddUser={handleAddUser}
        onClose={() => setOpen(false)}
        buttonDisabled={creatingUser}  // Disable button while adding user
        buttonLabel={creatingUser ? <CircularProgress size="sm" className="customSpinner" /> : "Add"} // Show spinner or "Add"
      />

      {/* Confirmation Modal for Deleting User */}
      <ConfirmationModal
        open={deleteUserOpen} 
        title="Delete User"
        message="Are you sure you want to delete this user? This action cannot be undone."
        actionLabel="Delete"
        cancelLabel="Cancel"
        onAction={handleDeleteUserConfirmed}  // Calls the delete action function
        onClose={() => setDeleteUserOpen(false)}  // Close modal on cancel
        loading={isDeletingUser}  // If you have a loading state, set this to true to enable spinner
        spinner={<CircularProgress size="sm" className="customSpinner" />} // Optional spinner for async actions
      />
      
    </Box>
  );
};

export default WorkspaceUsers;
