
// What? The main app component.
// Why? To define the main routes and components of the app.
// How?
// - Imports the necessary libraries.
// - Sets up the routes and components.
// - Renders the main components.

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import './App.css';

// Import views
import UserWorkspaces from './views/UserWorkspaces';
import Chat from './views/Chat/Chat';
import Workspace from './views/Workspace';
import WorkspaceFiles from './views/WorkspaceFiles';
import WorkspaceUsers from './views/WorkspaceUsers';
import WorkspaceSubscription from './views/WorkspaceSubscription';

import WorkspaceSources from './views/WorkspaceSources';
import WorkspaceFileRender from './views/WorkspaceFileRender';

// Nac container
import NavContainer from './components/NavContainer';
import NavContainerNoSidebar from './components/NavContainerNoSidebar';
import AuthProvider from './components/AuthProvider';
import ErrorMessageProvider from './components/ErrorMessageProvider';
import { WorkspaceProvider } from './providers/WorkspaceProvider'; 

// Stripe
import UserStats from './views/UserStats';
import UserUpgrade from './views/UserUpgrade';
import UserBilling from './views/UserBilling';

const App: React.FC = () => {
  return (
    <>
      <ErrorMessageProvider>
        <Router>
          <Routes>
            {/* Protected Routes */}
            <Route path="*" element={
              <AuthProvider>
                <Routes>
                    
                  {/* Home */}
                  <Route path="/" element={<NavContainerNoSidebar><UserWorkspaces /></NavContainerNoSidebar>} />

                  <Route path="/stats" element={<NavContainerNoSidebar><UserStats /></NavContainerNoSidebar>} />
                  <Route path="/billing" element={<NavContainerNoSidebar><UserBilling /></NavContainerNoSidebar>} />

                  {/* TODO: Replace with Stripe's tier table */}
                  <Route path="/upgrade" element={<NavContainerNoSidebar><UserUpgrade /></NavContainerNoSidebar>} />

                  <Route path="/workspace/:workspace_id" element={<WorkspaceProvider><Outlet/></WorkspaceProvider>}>
                    <Route path="" element={<NavContainer><Workspace /></NavContainer>} />
                    <Route path="chat" element={<NavContainer><Chat /></NavContainer>} />
                    <Route path="users" element={<NavContainer><WorkspaceUsers /></NavContainer>} />
                    <Route path="subscription" element={<NavContainer><WorkspaceSubscription /></NavContainer>} />
                    <Route path="sources" element={<NavContainer><WorkspaceSources /></NavContainer>} />
                    <Route path="files" element={<NavContainer><WorkspaceFiles /></NavContainer>} />
                    <Route path="file/:file" element={<WorkspaceFileRender />} />
                  </Route>
                </Routes>
              </AuthProvider>
              }/>
          </Routes>
        </Router>
      </ErrorMessageProvider>
    </>
  );
};
export default App;