// src

// What? A React component for managing a workspace's settings.
// Why? To allow the user to edit the workspace's description and assistant instructions.
// How?
// - Fetches workspace data using workspaceReadAPI.
// - Updates workspace data using workspaceUpdateAPI.
// - Handles beforeunload events to prevent data loss.
// - Displays the workspace's description and assistant instructions.
// - Allows the user to edit and save the description and assistant instructions.

import React, { useState, useEffect } from 'react';
import { useWorkspaceRead, useWorkspaceUpdate } from '../services/api';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Sheet } from '@mui/joy';
import { WorkspaceType } from '../common/types'; 
import Textarea from '@mui/joy/Textarea';
import { useBeforeUnload } from 'react-router-dom';

const Workspace = () => {
    const workspaceReadAPI = useWorkspaceRead();
    const workspaceUpdateAPI = useWorkspaceUpdate();
    const { workspace_id } = useParams<{ workspace_id: string }>();
    const [workspaceData, setWorkspaceData] = useState<WorkspaceType | null>(null);
    const [description, setDescription] = useState<string>(''); 
    const [assistantInstructions, setAssistantInstructions] = useState<string>(''); 
    const [initialDescription, setInitialDescription] = useState<string>('');
    const [initialAssistantInstructions, setInitialAssistantInstructions] = useState<string>('');
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [workspaceName, setWorkspaceName] = useState<string | null>(null);
    const viewName = "Pre Prompt";
  
    // What? A function to fetch the workspace name from localStorage.
    // Why? To set the workspace name in the component.
    // How?
    // - Fetches the workspace name from localStorage.
    // - Sets the workspace name in the state.
    useEffect(() => {
      const storedWorkspaceName = localStorage.getItem('workspaceName');
      if (storedWorkspaceName) {
        setWorkspaceName(storedWorkspaceName);
      }
    }, []);
  
    const title = workspaceName ? `${workspaceName} > ${viewName}` : viewName;

    // What? A function to fetch the workspace data from the API.
    // Why? To populate the workspace details in the component.
    // How?
    // - Fetches the workspace data using workspaceReadAPI.
    // - Updates the workspace data in the state.
    // - Sets the workspace description and assistant instructions in the state.
    // - Sets the initial description and assistant instructions in the state.
    useEffect(() => {
        const fetchData = async () => {
            if (workspace_id) {
                try {
                    const data = await workspaceReadAPI(workspace_id);
                    if (data) {
                        setWorkspaceData(data);
                        setDescription(data.description || ''); 
                        setAssistantInstructions(data.assistant_instructions || '');  
                        setInitialDescription(data.description || '');
                        setInitialAssistantInstructions(data.assistant_instructions || '');
                    }
                } catch (error: any) {
                    console.error('Failed to fetch workspace', error);
                }
            }
        };
        fetchData();
    }, [workspace_id]);

    // What? A function to check if the workspace data has changed.
    // Why? To determine if the user has made any changes to the workspace data.
    // How?
    // - Compares the current description and assistant instructions with the initial values.
    // - Sets isChanged to true if there are changes, false otherwise.
    useEffect(() => {
        if (description !== initialDescription || assistantInstructions !== initialAssistantInstructions) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [description, assistantInstructions, initialDescription, initialAssistantInstructions]);

    // What? A function to handle the saving of workspace data.
    // Why? To update the workspace data in the API.
    // How?
    // - Checks if the workspace ID is valid.
    // - Tries to update the workspace data using workspaceUpdateAPI.
    // - Logs any errors that occur during the update process.
    const handleSave = async () => {
        if (workspace_id) {
          try {
            await workspaceUpdateAPI(workspace_id, {
              name: workspaceName || '',
              description,
              assistant_instructions: assistantInstructions,
            });
            setInitialDescription(description);  
            setInitialAssistantInstructions(assistantInstructions);
            setIsChanged(false);  
          } catch (error: any) {
            console.error('Failed to update workspace', error);
          }
        }
    };

    // What? A function to enable the beforeunload warning only if there are unsaved changes.
    // Why? To prevent data loss if the user navigates away from the page without saving.
    // How?
    // - Adds an event listener for the beforeunload event.
    // - Sets the event return value to show the warning message.
    // - Removes the event listener when the component is unmounted.
    useEffect(() => {
        if (isChanged) {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                event.returnValue = ''; // Show the warning message
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [isChanged]);

    return (
        <Box sx={{ display: 'flex' }} className="tableBox">
            <Box component="main">
                <div className='headerContainer'>
                    <span className='title'>{title}</span>
                </div>
                <Sheet className="dataEntryBG" sx={{ width: 500, padding: '10px', marginTop: '20px' }}>
                    <Textarea
                        sx={{ marginBottom: '10px', height: '300px' }}
                        placeholder="Add a Description" 
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Textarea 
                        sx={{ marginBottom: '10px', height: '300px' }}
                        placeholder="Add assistant instructions" 
                        value={assistantInstructions}
                        onChange={(e) => setAssistantInstructions(e.target.value)}
                    />
                    <Button 
                        className={!isChanged ? 'disabledBtn' : 'primaryBtn'}  // Add a class to style it properly
                        disabled={!isChanged}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Sheet>
            </Box>
        </Box>
    );
};

export default Workspace;
