import React, { useEffect, useState } from 'react';
import { useGetBillingInfo } from '../../services/api';

const BillingInfo: React.FC = () => {
  const getBillingInfo = useGetBillingInfo();
  const [billingInfo, setBillingInfo] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBillingInfo();
      setBillingInfo(data);
    };
    fetchData();
  }, []);

  if (!billingInfo) {
    return <div>Loading...</div>;
  }

  const handleUpdatePaymentDetails = () => {
    // Logic for updating payment details (e.g., redirecting to a payment update form)
    console.log("Update Payment Details button clicked");
  };

  return (
    <div className="card">
      <h2>Billing Information</h2>
      <p>Current Payment Method: {billingInfo.paymentMethod}</p>
      <p>Next Billing Date: {billingInfo.nextBillingDate}</p>
      <p>Current Billing Cycle: {billingInfo.billingCycle}</p>

      {/* Update Payment Details Button */}
      <button className="primaryBtn" onClick={handleUpdatePaymentDetails}>Update Payment Details</button> 

      {/* Switch to Annual Billing Button */}
      <button className="primaryBtn">Switch to Annual Billing (Save 2 months!)</button>
    </div>
  );
};

export default BillingInfo;