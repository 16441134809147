import React, { useEffect, useState } from 'react';
import { useGetAvailableTiers } from '../../services/api';
import TierCard from './TierCard';

interface TierComparisonProps {
  currentTierRef: React.RefObject<HTMLDivElement>;
}

const TierComparison: React.FC<TierComparisonProps> = ({ currentTierRef }) => {
  const getAvailableTiers = useGetAvailableTiers();
  const [tiers, setTiers] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAvailableTiers();
      setTiers(data);
    };
    fetchData();
  }, []);

  if (!tiers.length) {
    return <div>Loading...</div>;
  }

  return (
    <div id="tier-comparison" className="card">
      <h2>Tier Comparison</h2>
      <div className="tier-cards">
        {tiers.map((tier, index) => (
          <TierCard key={index} tier={tier} tierRef={tier.isCurrent ? currentTierRef : undefined} />
        ))}
      </div>
    </div>
  );
};

export default TierComparison;