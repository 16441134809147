import React from 'react';
import '../../styles/TierCard.css'; // Assuming the CSS is in the styles folder

interface TierCardProps {
  tier: {
    name: string;
    price: string;
    features: string[];
    isCurrent: boolean;
    actionLabel: string;
  };
  tierRef?: React.RefObject<HTMLDivElement>; // Allow undefined
}

const TierCard: React.FC<TierCardProps> = ({ tier, tierRef }) => {
  return (
    <div
      className={`tier-card ${tier.isCurrent ? 'current-tier' : ''}`}
      ref={tierRef} // No need for null checks, ref will either be defined or undefined
    >
      <h3>{tier.name}</h3>
      <p>{tier.price}</p>
      <ul>
        {tier.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      {tier.isCurrent ? (
        <p className="current-plan-label">Current Plan</p>
      ) : (
        <button className="primaryBtn">{tier.actionLabel}</button>
      )}
    </div>
  );
};

export default TierCard;