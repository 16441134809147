// /src/components/Modals/UploadFileModal.tsx

// What? A modal for uploading files.
// Why? To allow users to upload files to a workspace.
// How?
// This component implements a modal for uploading files by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Using react-dropzone to handle file selection and drag-and-drop functionality
// 3. Checking for duplicate files against existing files in the workspace
// 4. Displaying a confirmation modal if duplicate files are detected
// 5. Handling file upload through the handleFilesUpload prop function
// 6. Managing UI states for drag-and-drop and confirmation interactions
// 7. Closing the modal after successful file upload or user cancellation

import React, { useState, useCallback } from 'react';
import { Modal, Box, Button } from '@mui/joy';  // Joy UI imports
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationModal from './ConfirmationModal'; // Import the confirmation modal

// How to instantiate:
// <UploadFileModal
//   open={open}
//   onClose={onClose}
//   handleFilesUpload={handleFilesUpload}
//   existingFiles={existingFiles}
// />
interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  handleFilesUpload: (files: File[]) => void;
  existingFiles: string[]; // Existing file names in the folder
}

// What? A modal for uploading files.
// Why? To allow users to upload files to a workspace.
// How?
// This component implements a modal for uploading files by:
// 1. Rendering a modal dialog when the 'open' prop is true (handled by Modal component)
// 2. Using react-dropzone to handle file selection and drag-and-drop functionality (useDropzone hook)
// 3. Checking for duplicate files against existing files in the workspace (handleDrop function)
// 4. Displaying a confirmation modal if duplicate files are detected (ConfirmationModal component)
// 5. Handling file upload through the handleFilesUpload prop function (handleConfirmReplace and handleCancelReplace functions)
// 6. Managing UI states for drag-and-drop interactions (setDragging state and useDropzone options)
// 7. Closing the modal after successful file upload or user cancellation (onClose function)
// 8. Handling clicks outside the modal to close it (handleBackdropClick function)
const UploadFileModal: React.FC<UploadFileModalProps> = ({ open, onClose, handleFilesUpload, existingFiles }) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [duplicateFiles, setDuplicateFiles] = useState<File[]>([]);  // Store duplicate files
  const [newFiles, setNewFiles] = useState<File[]>([]);  // Store non-duplicate files

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const duplicates = acceptedFiles.filter(file => existingFiles.includes(file.name));
    const newUploads = acceptedFiles.filter(file => !existingFiles.includes(file.name));

    if (duplicates.length > 0) {
      // Show confirmation modal for duplicates
      setDuplicateFiles(duplicates);
      setNewFiles(newUploads);
      setShowConfirmation(true);
    } else {
      // No duplicates, proceed with upload
      handleFilesUpload(acceptedFiles);
      onClose();  // Close modal after file selection
    }
  }, [existingFiles, handleFilesUpload, onClose]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
  });

  // Confirm replacement of duplicate files
  const handleConfirmReplace = () => {
    handleFilesUpload([...duplicateFiles, ...newFiles]);  // Upload duplicates and new files
    setShowConfirmation(false);
    onClose();  // Close the modal after confirmation
  };

  // Cancel the upload of duplicates, only upload new files
  const handleCancelReplace = () => {
    handleFilesUpload(newFiles);  // Upload only new files
    setShowConfirmation(false);
    onClose();
  };

  // Handle clicking outside the modal content to close the modal
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <Modal 
        open={open} 
        onClose={onClose}
        sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <Box 
          onClick={handleBackdropClick}  // Listen for clicks on the modal backdrop
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100vh', 
            textAlign: 'center', 
            padding: '20px',
          }}
        >
          <Box 
            {...getRootProps()} 
            sx={{ 
              border: `2px dashed ${isDragActive || dragging ? '#FF4500' : '#FF6F00'}`, 
              padding: '40px',  // Larger padding for bigger dropzone
              borderRadius: '10px', 
              width: '60%', 
              maxWidth: '600px', 
              backgroundColor: 'var(--primary-bg-color)',  // Ensure the dropzone stands out
              transition: 'border-color 0.3s ease-in-out',
            }}
          >
            <p>Drag and drop files here or click the button below to select files</p>
            <input {...getInputProps({ multiple: true })} />
            <Button 
              variant="solid" 
              className="primaryBtn" 
              startDecorator={<CloudUploadIcon />} 
              sx={{ marginTop: '16px' }}
            >
              Select Files
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal for Duplicate Files */}
      <ConfirmationModal
        open={showConfirmation}
        title={`Replace ${duplicateFiles.length} file${duplicateFiles.length > 1 ? 's' : ''}?`}
        message={`You are about to replace ${duplicateFiles.length} file${duplicateFiles.length > 1 ? 's' : ''} that already exist in the folder. Do you want to continue?`}
        actionLabel="Replace"
        cancelLabel="Cancel"
        onAction={handleConfirmReplace}
        onClose={handleCancelReplace}
      />
    </>
  );
};

export default UploadFileModal;