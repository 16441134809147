// /src/services/api.ts

// What? A file for the API services.
// Why? To centralize API calls, promoting code reusability and simplifying data management across the application.
// How?
// - Defines custom hooks for each API endpoint, encapsulating the API call logic
// - Uses the useAuthFetch hook to make authenticated API calls
// - Returns memoized functions that execute the API requests
// - Provides type-safe interfaces for API responses using TypeScript
// - Centralizes API endpoint definitions, making it easier to manage and update
// - Allows components to easily fetch and manage data from the backend

import { useCallback } from 'react';
import { FileType, User, UserWorkspacesType, WorkspaceUser, WorkspaceType, SubscriptionDetails, ResourceUsageData, UsageTrendData, Integration, BillingInfo, BillingHistoryItem, Tier, RequestPackage } from '../common/types';
import { useAuthFetch } from '../utils/useAuthFetch'
import { response } from 'express';

/*
  Mock API Instructions:
  Endpoint for returning static data in the same format as the real APIs. For easy implementation in frontend rendering.
  Ignore dynamic endpoints that add, remove, or otherwise need to modify the database. 
  
  Naming conventions:
    API endpoint name: /api/workspace/user/list
    Function name: workspaceUserList
    Hook name: useWorkspaceUserList
*/

/* Helper function to format integration name */
const formatIntegrationName = (name: string): string => {
  return name.toLowerCase().replace(/\s+/g, '-'); // Convert to lowercase and replace spaces with dashes
};

/*
  User APIs
*/
// What? A hook for fetching the user's workspaces.
// Why? To retrieve the list of workspaces associated with the current user.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the workspaces.
export const useUserWorkspaces = () => {
  const authFetch = useAuthFetch();

  const userWorkspaces = async (): Promise<UserWorkspacesType[]> => {
    return await authFetch(`/api/user/workspaces`, "list workspaces") as UserWorkspacesType[];
  };
  return userWorkspaces;
};

/*
  Workspace APIs
*/
// What? A hook for creating a new workspace.
// Why? To add a new workspace to the user's list of workspaces.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that creates a new workspace.
export const useWorkspaceCreate = () => {
  const authFetch = useAuthFetch();

  const workspaceCreate = async (workspaceName: string): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/create`, "create workspace", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: workspaceName,
      }),
    }) as WorkspaceType;
  };

  return workspaceCreate;
};

// What? A hook for reading a workspace by its ID.
// Why? To retrieve detailed information about a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the workspace by ID.
export const useWorkspaceRead = () => {
  const authFetch = useAuthFetch();

  const workspaceRead = async (workspaceId: string): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/read?workspace_id=${workspaceId}`, "read workspace") as WorkspaceType;
  };

  return workspaceRead;
};

export const useWorkspaceUpdate = () => {
  const authFetch = useAuthFetch();

  const workspaceUpdate = async (
    workspaceId: string,
    workspaceData: { name: string; description: string; assistant_instructions: string }
  ): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/update?workspace_id=${workspaceId}`, "update workspace", {
      method: 'PUT',  // PUT request for updates
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: workspaceData.name,
        description: workspaceData.description,
        assistant_instructions: workspaceData.assistant_instructions,
      }),
    }) as WorkspaceType;
  };

  return workspaceUpdate;
};

// What? A hook for deleting a workspace by its ID.
// Why? To remove a workspace from the user's list of workspaces.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that deletes the workspace by ID.
export const useWorkspaceDelete = () => {
  const authFetch = useAuthFetch();

  const workspaceDelete = async (workspaceId: string): Promise<void> => {
    return await authFetch(`/api/workspace/delete?workspace_id=${workspaceId}`, "delete workspace", {
      method: 'DELETE', 
      headers: {
        'Content-Type': 'application/json',
      }
    });
  };

  return workspaceDelete;
};

/*
 Workspace User APIs
*/
// What? A hook for listing users in a workspace.
// Why? To retrieve the list of users associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the users in the workspace. 
export const useWorkspaceUserList = () => {
  const authFetch = useAuthFetch();

  const workspaceUserList = async (workspaceId: string): Promise<WorkspaceUser[]> => {
    return await authFetch(`/api/workspace/user/list?workspace_id=${workspaceId}`, "list workspace users") as WorkspaceUser[];
  };

  return workspaceUserList;
};

export const useWorkspaceUserInviteSend = () => {
  const authFetch = useAuthFetch();

  const workspaceUserInviteSend = async (workspaceId: string, email: string, role: string) => {
    return await authFetch(`/api/workspace/user/invite/send?workspace_id=${workspaceId}`, "send user invite", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        role: role,
      }),
    });
  };

  return workspaceUserInviteSend;
};

/*
  Payment APIs
*/
// What? A hook for registering a payment for a workspace.
// Why? To record a payment made by a workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that registers the payment.
export const useWorkspacePaymentRegister = () => {
  const authFetch = useAuthFetch();
  
  const workspacePaymentRegister = async (workspaceId: string, subscription: string, amount: number) => {
    return await authFetch(`/api/workspace/payment/register?workspace_id=${workspaceId}`, "register payment", {
      method: 'POST',
      body: JSON.stringify({
        subscription: subscription,
        amount: amount,
      }),
    });
  };

  return workspacePaymentRegister;
};

export const useWorkspacePaymentList = () => {
  const authFetch = useAuthFetch();

  const workspacePaymentList = async (workspaceId: string) => {
    return await authFetch(`/api/workspace/payment/list?workspace_id=${workspaceId}`, "list payments");
  };

  return workspacePaymentList;
};

/*
  Workspace user permissions
*/
// What? A hook for updating the role of a user in a workspace.
// Why? To modify the permissions or roles of a user within a workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that updates the user's role in the workspace.
export const useWorkspaceUserUpdate = () => {
  const authFetch = useAuthFetch();

  const workspaceUserUpdate = async (workspaceId: string, role: string, userId: string) => {
    return await authFetch(`/api/workspace/user/update?workspace_id=${workspaceId}`, "update user permissions", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: role,
        id: userId,
      }),
    });
  };

  return workspaceUserUpdate;
}

// What? A hook for removing a user from a workspace.
// Why? To remove a user from a workspace, typically when the user is no longer associated with the workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that removes the user from the workspace.
export const useWorkspaceUserRemove = () => {
  const authFetch = useAuthFetch();

  const workspaceUserRemove = async (workspaceId: string, userId: string): Promise<void> => {
    return await authFetch(`/api/workspace/user/remove?workspace_id=${workspaceId}`, "remove user from workspace", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId })
    });
  };

  return workspaceUserRemove;
};

// What? A hook for accepting an invite to a workspace.
// Why? To allow a user to join a workspace after receiving an invite.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that accepts the invite to the workspace.
export const useWorkspaceUserAcceptInvite = () => {
  const authFetch = useAuthFetch();

  const workspaceUserAcceptInvite = async (workspaceId: string): Promise<UserWorkspacesType> => {
    return await authFetch(`/api/workspace/user/invite/accept?workspace_id=${workspaceId}`, "accept workspace invite", {
      method: 'POST',
    }) as UserWorkspacesType;
  };

  return workspaceUserAcceptInvite;
};

/*
  User APIs
*/
// What? A hook for removing a user by their ID.
// Why? To remove a user from the system, typically when the user is no longer active or should be removed from the system.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that removes the user by ID.
export const useRemoveUser = () => {
  const authFetch = useAuthFetch();

  const removeUser = async (id: string): Promise<void> => {
    return await authFetch(`/api/user/remove`, "delete user", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
  };

  return removeUser;
};

// What? A hook for reading the current user's information.
// Why? To retrieve the details of the currently authenticated user.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the user's information.
export const useReadUser = () => {
  const authFetch = useAuthFetch();

  const readUser = async (): Promise<User> => {
    return await authFetch(`/api/user/read`, "read user") as User;
  };

  return readUser;
};

/* 
  File APIs
*/
// What? A hook for listing files in a workspace.
// Why? To retrieve the list of files associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the files in the workspace.
export const useListFiles = () => {
  const authFetch = useAuthFetch();

  const listFiles = async (workspaceId: string): Promise<FileType[]> => {
    return await authFetch(`/api/workspace/file/list?workspace_id=${workspaceId}`, "list files") as FileType[];
  };

  return listFiles;
};

// What? A hook for deleting a file by its ID.
// Why? To remove a file from a workspace, typically when the file is no longer needed.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that deletes the file by ID.
export const useDeleteFile = () => {
  const authFetch = useAuthFetch();

  const deleteFile = async (workspaceId:string, fileId: string) => {
    return await authFetch(`/api/workspace/file/delete?workspace_id=${workspaceId}&file_path=${fileId}`, "delete file", {
      method: 'DELETE',
    });
  };

  return deleteFile;
};

/* 
  File Upload API 
*/
// What? A hook for uploading a file to a workspace.
// Why? To add a file to a workspace, typically for storing or processing.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that uploads the file to the workspace.
export const useUploadFile = () => {
  const authFetch = useAuthFetch();

  const uploadFile = async (workspaceId: string, file: File): Promise<FileType> => {
    const formData = new FormData();
    formData.append('file', file);

    return await authFetch(`/api/workspace/file/upload?workspace_id=${workspaceId}`, "upload file", {
      method: 'POST',
      body: formData,
    },
    30000
    );
  };

  return uploadFile;
};

/*
  Get file bin
*/
// What? A hook for reading a citation from a file.
// Why? To retrieve detailed information about a specific citation from a file.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the citation from the file.
export const useWorkspaceCitationRead = () => {
  const authFetch = useAuthFetch("");

  const workspaceCitationRead = async (workspaceId: string, fileId: string): Promise<any> => {
    return await authFetch(`/api/workspace/citation/read?workspace_id=${workspaceId}&file_id=${fileId}`, "read file");
  };

  return workspaceCitationRead;
};

export const useWorkspaceSourceFileDownload = () => {
  const authFetch = useAuthFetch("");

  const workspaceSourceFileDownload = async (workspaceId: string, fileId: string): Promise<any> => {
    return await authFetch(`/api/workspace/source/file/download?workspace_id=${workspaceId}&file_id=${fileId}`, "download file");
  };

  return workspaceSourceFileDownload;
};

/*
  Mock API Hooks for Subscription Management - UPDATE
*/

// What? A hook to retrieve details about a subscription.
// Why? Used to type out limits or restrict access based on reached limits.
// How?
// TODO: Replace with Stripe requests.
export const useGetSubscriptionDetails = () => {
  const getSubscriptionDetails = async (): Promise<SubscriptionDetails> => {
    // Mock data
    return {
      currentTier: 'Team',
      monthlyPrice: 75,
      yearlyPrice: 750,
      benefits: ['5 Workspaces', '500 Requests', 'Unlimited Users', '50GB Storage'],
    };
  };
  return getSubscriptionDetails;
};

// What? A hook to retrieve resource usage data.
// Why? To display current resource usage and limits to the user.
// How? Returns a function that fetches mock resource usage data (to be replaced with actual API calls).
// TODO: Replace with actual API call.
export const useGetResourceUsage = () => {
  const getResourceUsage = async (): Promise<ResourceUsageData> => {
    // Mock data
    return {
      resources: [
        {
          name: 'Requests',
          used: 375,
          total: 500,
          percentage: 75,
          message: 'You are approaching your quota. Please consider adding more requests to your account.',
        },
        {
          name: 'Storage',
          used: 30,
          total: 50,
          percentage: 60,
          message: '',
        },
        {
          name: 'Workspaces',
          used: 4,
          total: 5,
          percentage: 80,
          message: '',
        },
      ],
    };
  };
  return getResourceUsage;
};

// What? A hook to retrieve usage trend data.
// Why? To display historical usage trends to the user.
// How? Returns a function that fetches mock usage trend data (to be replaced with actual API calls).
// TODO: Replace with actual API call.
export const useGetUsageTrends = () => {
  const getUsageTrends = async (timeframe: string): Promise<UsageTrendData> => {
    // Mock data based on timeframe
    const dataMap: { [key: string]: number[] } = {
      '30': [100, 200, 150, 300],
      '90': [100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650],
      '365': Array.from({ length: 52 }, () => Math.floor(Math.random() * 700) + 100),
    };

    const labels = dataMap[timeframe].map((_, i) => `Week ${i + 1}`);
    return {
      labels,
      datasets: [
        {
          label: 'Requests Used',
          data: dataMap[timeframe],
          borderColor: 'rgb(255, 111, 0)',
          backgroundColor: 'rgba(255, 111, 0, 0.1)',
          tension: 0.1,
          fill: true,
        },
      ],
      options: {
        responsive: true,
        plugins: {
          legend: { position: 'top' },
          title: { display: true, text: 'Request Usage Over Time' },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: { display: true, text: 'Requests' },
          },
          x: {
            title: { display: true, text: 'Time' },
          },
        },
      },
    };
  };
  return getUsageTrends;
};

// What? A hook for retrieving integrations for a workspace.
// Why? To fetch and display the list of integrations associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a memoized function that fetches the integrations for a given workspace ID.
// - The function is memoized using useCallback to optimize performance and prevent unnecessary re-renders.
export const useGetIntegrations = () => {
  const authFetch = useAuthFetch();

  const getIntegrations = useCallback(
    async (workspaceId: string): Promise<Integration[]> => {
      const response = await authFetch(`/api/workspace/source/list?workspace_id=${workspaceId}`, "list integrations");
      return response as Integration[];
    },
    [authFetch]
  );

  return getIntegrations;
};

// What? A hook for creating a new integration.
// Why? To add a new integration to a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that creates a new integration with the given type, workspace ID, and configuration data.
// - Formats the integration type to match the expected API format.
// - Handles errors and logs them to the console.
export const useCreateIntegration = () => {
  const authFetch = useAuthFetch();

  const createIntegration = async (integrationType: string, workspaceId: string, data: any): Promise<Integration> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      const response = await authFetch(`/api/workspace/source/create?workspace_id=${workspaceId}`, "create integration", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      return response as Integration;
    } catch (error: any) {
      console.error(`Error creating ${formattedType}:`, error);
      throw error;
    }
  };

  return createIntegration;
};

// What? A hook for updating an existing integration.
// Why? To modify the configuration or settings of an integration in a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that updates an integration with the given type, workspace ID, and updated configuration data.
// - Formats the integration type to match the expected API format.
// - Handles errors and logs them to the console.
export const useUpdateIntegration = () => {
  const authFetch = useAuthFetch();

  const updateIntegration = async (integrationType: string, workspaceId: string, data: any): Promise<Integration> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      const response = await authFetch(`/api/workspace/source/update?workspace_id=${workspaceId}`, "update integration", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      return response as Integration;
    } catch (error: any) {
      console.error(`Error updating ${formattedType}:`, error);
      throw error;
    }
  };

  return updateIntegration;
};

// What? A hook for deleting an existing integration.
// Why? To remove an integration from a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that deletes an integration with the given type, workspace ID, and integration name.
// - Formats the integration type to match the expected API format.
// - Encodes the integration name to ensure proper URL formatting.
// - Handles errors and logs them to the console.
export const useDeleteIntegration = () => {
  const authFetch = useAuthFetch();

  const deleteIntegration = async (integrationType: string, workspaceId: string, integrationName: string): Promise<void> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      await authFetch(`/api/workspace/source/remove?workspace_id=${workspaceId}&source_name=${encodeURIComponent(integrationName)}`, "delete integration", {
        method: 'DELETE',
      });
    } catch (error: any) {
      console.error(`Error deleting ${formattedType}:`, error);
      throw error;
    }
  };

  return deleteIntegration;
};

// TODO: Replace with Stripe integration.
export const useGetBillingInfo = () => {
  const getBillingInfo = async (): Promise<BillingInfo> => {
    // Mock data
    return {
      paymentMethod: 'Visa ending in 1234',
      nextBillingDate: 'June 1, 2024',
      billingCycle: 'Monthly',
    };
  };
  return getBillingInfo;
};

// TODO: Replace with Stripe integration.
export const useGetBillingHistory = () => {
  const getBillingHistory = async (): Promise<BillingHistoryItem[]> => {
    // Mock data
    return [
      {
        date: 'May 1, 2024',
        description: 'Monthly Subscription - Team Tier',
        amount: 75.0,
      },
      {
        date: 'Apr 1, 2024',
        description: 'Monthly Subscription - Team Tier',
        amount: 75.0,
      },
    ];
  };
  return getBillingHistory;
};

// TODO: Remove and replace with pricing table from Stripe.
export const useGetAvailableTiers = () => {
  const getAvailableTiers = async (): Promise<Tier[]> => {
    // Mock data
    return [
      {
        name: 'Free',
        price: 'No cost',
        priceYearlyBilling: undefined,
        features: ['1 Workspace', '30 Requests', '1GB Storage', '-'],
        isCurrent: false,
        actionLabel: 'Downgrade',
        iconName: undefined
      },
      {
        name: 'Base',
        price: '€15/month',
        priceYearlyBilling: '€12/month',
        features: ['1 Workspace', '100 Requests', '10GB Storage', '-'],
        isCurrent: false,
        actionLabel: 'Downgrade',
        iconName: 'Person'
      },
      {
        name: 'Team',
        price: '€75/month',
        priceYearlyBilling: '€60/month',
        features: ['5 Workspaces', '500 Requests', '50GB Storage', '-'],
        isCurrent: true,
        actionLabel: '',
        iconName: 'People'
      },
      {
        name: 'Company',
        price: '€150/month',
        priceYearlyBilling: '€125/month',
        features: ['10 Workspaces', '1,000 Requests', '100GB Storage', 'Basic Integrations'],
        isCurrent: false,
        actionLabel: 'Upgrade',
        iconName: 'Groups'
      },
      {
        name: 'Enterprise',
        price: 'Contact Sales',
        priceYearlyBilling: undefined,
        features: ['Unlimited Workspaces', '10,000 Requests', '1TB Storage', 'All Integrations'],
        isCurrent: false,
        actionLabel: 'Contact Sales',
        iconName: 'Language'
      },
    ];
  };
  return getAvailableTiers;
};

// TODO: Replace with Stripe integration.
export const useUpgradeTier = () => {
  const upgradeTier = async (newTier: string): Promise<void> => {
    // Mock implementation
    console.log(`Tier upgraded to: ${newTier}`);
  };
  return upgradeTier;
};

// TODO: Replace with Stripe integration.
export const useGetRequestPackages = () => {
  const getRequestPackages = async (): Promise<RequestPackage[]> => {
    // Mock data for request packages
    return [
      {
        amount: 100,
        price: 20,
        discount: 0, // No discount for the smallest package
      },
      {
        amount: 500,
        price: 90,
        discount: 10, // 10% discount
      },
      {
        amount: 1000,
        price: 160,
        discount: 20, // 20% discount
      },
    ];
  };
  return getRequestPackages;
};

// TODO: Replace with Stripe integration.
export const useAddMoreRequests = () => {
  
  // Define the available request packages
  const requestPackages: { [key: number]: RequestPackage } = {
    100: { amount: 100, price: 20, discount: 0 },
    500: { amount: 500, price: 90, discount: 10 }, // 10% discount
    1000: { amount: 1000, price: 160, discount: 20 } // 20% discount
  };

  const addMoreRequests = async (packageId: number): Promise<number> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Check if the packageId is valid
        if (requestPackages[packageId]) {
          const { amount } = requestPackages[packageId];
          console.log(`Simulating purchase of ${amount} requests from package ${packageId}...`);
          resolve(amount); // Return the number of requests in the package
        } else {
          reject(new Error('Invalid package selected')); // Invalid packageId
        }
      }, 1000); // Simulate a network delay of 1 second
    });
  };

  return addMoreRequests;
};

// TODO: Replace with Stripe integration.
export const useSwitchBillingCycle = () => {
  const switchBillingCycle = async (newCycle: 'Monthly' | 'Annual'): Promise<void> => {
    // Mock implementation
    console.log(`Billing cycle switched to: ${newCycle}`);
  };
  return switchBillingCycle;
};

// What? A hook for adding an integration.
// Why? To add a new integration to a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that adds an integration with the given name.
// - Handles errors and logs them to the console.
export const useAddIntegration = () => {
  const addIntegration = async (integrationName: string): Promise<void> => {
    // Mock implementation
    console.log(`Integration added: ${integrationName}`);
  };
  return addIntegration;
};

