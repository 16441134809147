import React, { useEffect, useState } from 'react';
import { useGetBillingHistory } from '../../services/api';
import '../../styles/BillingHistory.css'; // Import the new CSS file

const BillingHistory: React.FC = () => {
  const getBillingHistory = useGetBillingHistory();
  const [history, setHistory] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBillingHistory();
      setHistory(data);
    };
    fetchData();
  }, []);

  if (!history.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card">
      <h2>Billing History</h2>
      <table className="billing-history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>{item.description}</td>
              <td>€{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="primaryBtn">View Full History</button>
    </div>
  );
};

export default BillingHistory;