// What? A React component for the chat interface.
// Why? To provide a chat interface for the user.
// How? The component's logic flow:
// 1. Initialize: Set up state (chat history, polling status), fetch workspace details
// 2. API Interaction: Use useAuthFetch for authenticated calls to backend
// 3. User Input: Capture and send new messages, update local state
// 4. Real-time Updates: Poll for new messages using useCallback and setTimeout
// 5. UI Management: Render chat messages, auto-scroll to latest using useRef
// 6. Navigation: Use useNavigate for moving between different app views
// 7. Cleanup: Manage side effects with useEffect, ensure proper state updates
//
// This flow creates an interactive, real-time chat interface within the workspace.
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuthFetch } from '../../utils/useAuthFetch';
import { useParams, useNavigate } from 'react-router-dom';

import { ChatInput } from "../../components/ChatInput";
import { ChatMessage } from "../../components/ChatMessage";
import { ClearChatButton } from "../../components/ClearChatButton";

import styles from "./Chat.module.css";
import { CircularProgress } from '@mui/joy';

const Chat: React.FC = () => {
    const authFetch = useAuthFetch();
    const navigate = useNavigate();

    const [chatHistory, setChatHistory] = useState<Record<string, any>[]>([]);
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const [workspace, setWorkspace] = useState<{ name: string } | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const { workspace_id } = useParams<{ workspace_id: string }>();

    const chatHistoryRef = useRef(chatHistory);
    chatHistoryRef.current = chatHistory;

    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    /*
        Manage chat interface
    */
    const pollHistory = useCallback(async () => {
        const start = performance.now();
        try {
            const newHistory = await authFetch(
                `/api/workspace/assistant/chat?workspace_id=${workspace_id}&mode=async`, "get AI response",
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(chatHistoryRef.current)
                }, 1000 * 30)
            setChatHistory(newHistory);
        } catch (error) {
            console.log("Error fetching:", error);
        }
        const end = performance.now();

        if (isComplete()) {
            setIsPolling(false)
        } else {
            setTimeout(pollHistory, Math.max(500 - (end - start), 10))
        }
    }, [authFetch, workspace_id]);

    const isComplete = () => {
        if (chatHistoryRef.current.length === 0) {
            return false;
        }
        const m = chatHistoryRef.current[chatHistoryRef.current.length - 1];
        return "request_response" in m && m["request_response"];
    };

    useEffect(() => {
        console.log(chatHistory);

        // Start polling if not already polling
        if (chatHistory.length >= 1 && isPolling === false && !isComplete()) {
            setIsPolling(true);
            pollHistory();
        }

    }, [isPolling, chatHistory, pollHistory]);

    /*
        Fetch Workspace Details
    */
    useEffect(() => {
        const fetchWorkspaceDetails = async () => {
            try {
                const data = await authFetch(`/api/workspace/read?workspace_id=${workspace_id}`, "read workspace");
                setWorkspace(data);
            } catch (error) {
                console.error('Error fetching workspace details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchWorkspaceDetails();
    }, [workspace_id]);

    /*
        User callbacks
    */
    const clearChat = () => {
        setChatHistory([]);
    };

    /*
        Helpers
    */
    const shouldAddDefaultProgress = () => {
        if (chatHistoryRef.current.length === 0) {
            return false;
        }
        const m = chatHistoryRef.current[chatHistoryRef.current.length - 1];
        return !m.status_notification && !isComplete();
    };

    useEffect(() => {
        if (chatMessageStreamEnd.current) {
            chatMessageStreamEnd.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    if (loading) {
        return (
            <div className={styles.container}>
                <div className={styles.chatRoot}>
                    <div className={styles.loadingState}>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!chatHistory.length ? (
                        <div className={styles.chatEmptyState}>
                            <h1 className={styles.chatEmptyStateTitle}>{workspace ? workspace.name : <CircularProgress />}</h1>
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {chatHistory.map((message: any, index: number) => (
                                <div key={index}>
                                    <ChatMessage message={message} />
                                </div>
                            ))}
                            {shouldAddDefaultProgress() && (<div><ChatMessage message={{ role: "assistant", status: "in_progress", status_notification: "Metalmind is thinking" }} /></div>)}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <div className={styles.chatRow}>
                            <ChatInput
                                clearOnSend
                                placeholder="Ask me a question"
                                disabled={isPolling}
                                onSend={(content: string) => { setChatHistory([...chatHistory, { "role": "user", "content": [{ "type": "text", "content": content }] }]) }}
                            />
                            <div className={styles.commandsContainer}>
                                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!chatHistory.length || isPolling} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
