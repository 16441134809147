// /src/components/AuthProvider.tsx

// What? Handles authentication for the application.
// Why? To manage user authentication and authorization for different environments.
// How?
// - Configures Auth0 or Entra ID for authentication.
// - Provides protected routes for authenticated users.
// - Handles user status checks and redirects for unverified users.

import React, { ReactNode, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAuth0, Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';

import { PublicClientApplication, InteractionStatus, InteractionType, InteractionRequiredAuthError, AuthError } from '@azure/msal-browser';
import { MsalProvider, useMsal, MsalAuthenticationTemplate} from '@azure/msal-react';

import { UnsafeAuthProvider } from './UnsafeAuthProvider';
import { useReadUser } from '../services/api';
import Unverified from '../views/Unverified';
import NavContainerNoSidebar from './NavContainerNoSidebar';

////////////////////
// Configurations //
////////////////////
// EntraID Configuration (TODO: Add to env variables)
export const msalConfig = {
  auth: {
    //clientId: "635cc49c-e3c4-4d0b-ba1c-8668b05451a9",
    clientId: "0501f640-94a0-4fd9-a7ae-0e8fa5938130",
    authority: "https://login.microsoftonline.com/common/v2.0",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

/////////////////////
// Protected Route //
/////////////////////
interface ProtectedRouteAuth0Props {
  component: React.ComponentType;
}

// What? A protected route for authenticated users.
// Why? To ensure that only authenticated users can access certain parts of the application.
// How?
// - Uses the useAuth0 hook to check if the user is authenticated.
// - Uses the useReadUser hook to fetch user data.
// - If the user is not authenticated, redirects to the login page.
// - If the user is not verified, redirects to the unverified page.
// - Otherwise, renders the component passed as a prop.

const ProtectedRouteAuth0: React.FC<ProtectedRouteAuth0Props> = ({ component }) => {
  const location = useLocation();
  const { logout } = useAuth0();


  const { isAuthenticated } = useAuth0();
  const readUserAPI = useReadUser(); // Hook to fetch user data
  const navigate = useNavigate();
  const [isUserChecked, setIsUserChecked] = useState(false); // Track if user status is checked

  React.useEffect(() => {
    const checkUserStatus = async () => {
      if (isAuthenticated) {
        try {
          const user = await readUserAPI(); // Fetch user data
          console.log('User data:', user);
          if (user.status === "created") { // Auth0 account not activated, redirect to "/Unverified"
          // if(true) { // Uncomment this line for testing
            navigate('/unverified');
          }
        } catch (error) {
          console.error('Failed to fetch user status:', error);
        } finally {
          setIsUserChecked(true); // Mark that user status has been checked
        }
      }
    };

    if (isAuthenticated && !isUserChecked) {
      checkUserStatus(); // Only check user status after login and once
    }
  }, [isAuthenticated, isUserChecked, readUserAPI, navigate]);


  React.useEffect(() => {
    if (location.pathname === '/logout') {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }, [location, logout]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div>Loading...</div>,
  });

  return <Component />;
};

interface ProtectedRouteEntraIDProps {
  children: ReactNode;
}

// What? A protected route for authenticated users.
// Why? To ensure that only authenticated users can access certain parts of the application.
// How?
// - Uses the useMsal hook to check if the user is authenticated.
// - Uses the useReadUser hook to fetch user data.
// - If the user is not authenticated, redirects to the login page.
// - If the user is not verified, redirects to the unverified page.
// - Otherwise, renders the component passed as a prop.
const ProtectedRouteEntraID: React.FC<ProtectedRouteEntraIDProps> = ({ children }) => {
  const location = useLocation();
  const { instance, inProgress, accounts } = useMsal();

  React.useEffect(() => {
    const logout = async () => {
      if (location.pathname === '/logout' && inProgress === InteractionStatus.None) {
        await instance.initialize();
        instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin,
        });
      }
    }

    logout();
  }, [location, instance, inProgress]);

  return (<>{children}</>)
};

/////////////////////////////////////
// Authentication Provider Wrapper //
/////////////////////////////////////
interface AuthProviderProps {
  children: ReactNode;
}

// What? An authentication provider wrapper.
// Why? To handle authentication for the application.
// How?
// - Checks the authentication method from environment variables.
// - If the method is Auth0, uses the Auth0Provider.
// - If the method is Entra ID, uses the MsalProvider.
// - Otherwise, uses the UnsafeAuthProvider.

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  // Mock API
  if (process.env.REACT_APP_API_URL === "http://localhost:3001") return <UnsafeAuthProvider>{children}</UnsafeAuthProvider>;

  // Auth0
  if (process.env.REACT_APP_AUTHENTICATION_METHOD === "AUTH0") {
    const onAuth0RedirectCallback = (appState: any) => {
      window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
    };

    const scope: string = "openid profile email offline_access";

    const domain: string = process.env.REACT_APP_AUTH0_DOMAIN as string;
    const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
    const audience: string = process.env.REACT_APP_AUTH0_AUDIENCE as string;

    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        onRedirectCallback={onAuth0RedirectCallback}
        scope={scope}
        authorizationParams={{
          scope: scope,
          audience: audience,
          redirect_uri: window.location.origin
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <Routes>
          {/* Unverified Route */}
          <Route path="/unverified" element={<NavContainerNoSidebar><Unverified /></NavContainerNoSidebar>} />
          <Route path="*" element={
            <ProtectedRouteAuth0 component={() => <>{children}</> }/>
          }/>
        </Routes>
      </Auth0Provider>
    );
  }

  // Entra ID
  if (process.env.REACT_APP_AUTHENTICATION_METHOD === "ENTRAID") {
    const msalInstance = new PublicClientApplication(msalConfig);
    const LoadingComponent = () => <p>Loading...</p>;

    const authenticationRequest = {
      scopes: ["User.Read", "openid", "profile"],
    };

    interface ErrorComponentProps {
        error: AuthError | null; // The error type expected by MSAL
    }

    const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
        const { instance } = useMsal();

        if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect(authenticationRequest);
            return <div>Redirecting to login...</div>;
        }

        return (
            <div>
                {error ? `An error occurred: ${error.errorMessage}` : 'An unknown error occurred.'}
            </div>
        );
    };

    return (
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect}
          authenticationRequest={authenticationRequest}
          loadingComponent={LoadingComponent}
          errorComponent={ErrorComponent}
        >
          <ProtectedRouteEntraID>
            <>{children}</>
          </ProtectedRouteEntraID>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  }

  // No authentication
  return <UnsafeAuthProvider>{children}</UnsafeAuthProvider>;
};

export default AuthProvider;